import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import { Rating } from 'react-simple-star-rating'
import { Container } from 'react-bootstrap';
import FilmsBadges from '../FilmsBadges';

class FilmsTopItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      filter: props.filter,
      index: props.top,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.state.userFilm = snapshotUserFilm.val();
      } else {
        this.state.userFilm = {
          userFilmNote: '',
          userFilmRank: ''
        }
      }
    })
  }

  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }

  render () {
    const { film, userFilm, filmType, index, filter } = this.state
    return (
      <>
            { ((filter === 'comite' && U.showTopFilms()) || (filter === 'personal' && U.showTopFilmsPersonal()) || filter !== 'personal' && filter !== 'comite' && U.isUserAdmin()) && film.isSelectable && (
            <Card className={ (filter !== 'personal' && film.userFilmScoreRank > 0) || (filter === 'personal' && film.userPersonalScore > 0) ? 'mb-3 pt-3' : 'deactivated mb-3 pt-3' }>
              <Card.Link href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid } style={{textDecoration: 'none'}}>
              <span className='top'>{index}</span>
              <div className="pl-4 pr-4 pb-1 pt-0 ml-4">
                <FilmsBadges key={film.uid} film={film} filmType={filmType}/>
              </div>
              <Row style={{ maxWidth: '100%' }}>
                <Col xs={8}>
                  <Container className='pr-4 pl-4 pb-0 pt-0'>
                    <h3 className="mb-0" style={{ fontSize: '1.5em', color: '#dc3545',textDecorationColor:'#dc3545'  }}>
                      <strong>{ film.filmName }</strong>
                      </h3>
                      {filter !== 'personal' ? (
                        <>
                          <Rating size='20' initialValue={ film.userFilmScoreRank ? Math.round(film.userFilmScoreRank) : 0 } readonly={true} /><br /> <span className='h3 text-muted'>{film.userFilmScoreRank ? film.userFilmScoreRank : ''}</span>
                        </>
                      ) : (
                        <>
                          <Rating size='20' initialValue={ film.userPersonalScore ? Math.round(film.userPersonalScore) : 0 } readonly={true} /><br /> <span className='h3 text-muted'>{film.userPersonalScore ? film.userPersonalScore.toFixed(2) : ''}</span>
                        </>
                      )}
                    <p className='text-muted mb-2'>
                      <small>{film.filmYear} · <strong>{film.filmLengthFixed ? film.filmLengthFixed : film.filmLength} min</strong> · {film.filmGenre ? film.filmGenre : ''} · {film.filmCity}, {film.filmState} ({film.filmCountry.substring(0,3).toUpperCase()})</small>
                      </p>
                      { U.showTopFilms() && (
                      <hr className='m-0' />
                      )}
                    <small >
                      { U.showTopFilms() && (
                      <pre className='text-muted pt-2'>
                      {film.userFilmPeopleNames}
                    </pre>
                    )}
                    </small>
                  </Container>
                </Col>

                <Col xs={4} className='p-0 pb-4 text-right'>
                  
                  { (film.imageCover || film.imageFrame1) && (
                  <Card.Img src={this.resizedImage(film.imageCover ? film.imageCover : film.imageFrame1)} style={{ maxHeight: '200px', width: 'auto', maxWidth: '100%' } } />
                )}

                </Col>
              </Row>
              
              </Card.Link>

            { U.isUserAdmin() && (
              <>
                <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid } className={'btn btn-sm text-white font-weight-bold ' + (film.isSelectable === undefined ? 'bg-warning' : (film.isSelectable === false ? 'bg-danger' : 'bg-success')) }>Administrar</a>
              </>
            )}
            </Card>
        )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsTopItem);