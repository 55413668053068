import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import CardGroup from 'react-bootstrap/CardGroup'
import Alert from 'react-bootstrap/Alert'
import { PiWarningDiamondFill } from 'react-icons/pi';
import { Badge, Container } from 'react-bootstrap';
import './index.scss';


const filmTypes = ['LLARGS','DOCS','CURTS','VIDEOCLIPS','CENTRES']

const Landing = () => (
  <Container>
    <h2 className="mt-4 mb-4">Quin tipus de producció vols inscriure? <small>Selecciona la categoria en la qual participarà la teva producció.</small></h2>
    <CardGroup className='Landing'>
          { filmTypes.map((type, key) => ( 
              <Card key={key} className={ CONFIG.SIGNIN_FILMS ? '' : 'disabled'}>
              {Date.now() < CONFIG.SIGNIN_FILMS_START && (
                <Badge variant="error">OPERTURA D'INSCRIPCIONS:<br/><small>{U.datetoHuman(CONFIG.SIGNIN_FILMS_START)}</small></Badge>
              )}
              {Date.now() > CONFIG.SIGNIN_FILMS_END && (
                <Badge variant="error">INSCRIPCIONS TANCADES:<br/><small>{U.datetoHuman(CONFIG.SIGNIN_FILMS_END)}</small></Badge>
              )}
              <Card.Link href={ (Date.now() > CONFIG.SIGNIN_FILMS_START && Date.now() < CONFIG.SIGNIN_FILMS_END ) ? ROUTES['FORM_' + type ] : undefined }>
                <Card.Img variant="top" src={"/img/" + type.toLowerCase() + ".jpg" }/>
              <Card.Body>
                <Card.Title>{MESSAGES[type]}</Card.Title>
                <Card.Text>
                  <small className='text-muted'>{MESSAGES[type + '_FORM_DESC']}</small>
                </Card.Text>
              </Card.Body>
              {(Date.now() > CONFIG.SIGNIN_FILMS_START && Date.now() < CONFIG.SIGNIN_FILMS_END ) && (
              <Badge bg="dark"><small>Inscripcions obertes fins:<br/> {U.datetoHuman(CONFIG.SIGNIN_FILMS_END)}</small></Badge>
              )}
              </Card.Link>
            </Card>
          ))}
        <Card className={ CONFIG.SIGNIN_YOUNG ? '' : 'disabled'}>
          {Date.now() < CONFIG.SIGNIN_YOUNG_START && (
            <Badge variant="error">OBERTURA D'INSCRIPCIONS:<br/><small>{U.datetoHuman(CONFIG.SIGNIN_YOUNG_START)}</small></Badge>
          )}
            {Date.now() > CONFIG.SIGNIN_YOUNG_END && (
            <Badge variant="error">INSCRIPCIONS TANCADES:<br/><small>{U.datetoHuman(CONFIG.SIGNIN_YOUNG_END)}</small></Badge>
          )}
          <Card.Link href={ (Date.now() > CONFIG.SIGNIN_YOUNG_START && Date.now() < CONFIG.SIGNIN_YOUNG_END ) ? ROUTES.FORM_YOUNG : undefined }>
            <Card.Img variant="top" src="/img/jove.jpg"/>
          <Card.Body>
            <Card.Title>Concurs Jove</Card.Title>
            <Card.Text>
              <small className='text-muted'>{MESSAGES.JOVES_FORM_DESC}</small>
            </Card.Text>
          </Card.Body>
          { (Date.now() > CONFIG.SIGNIN_YOUNG_START  && Date.now() < CONFIG.SIGNIN_YOUNG_END  ) && (
            <Badge bg="dark"><small>Inscripcions obertes fins<br/>{U.datetoHuman(CONFIG.SIGNIN_YOUNG_END)}</small></Badge>
            )}
          </Card.Link>
        </Card>
        <Card className={CONFIG.SIGNIN_PITCH ? '' : 'disabled'}>
        {Date.now() < CONFIG.SIGNIN_PITCH_START && (
            <Badge variant="error">OBERTURA D'INSCRIPCIONS:<br/><small>{U.datetoHuman(CONFIG.SIGNIN_PITCH_START)}</small></Badge>
          )}
            {Date.now() > CONFIG.SIGNIN_PITCH_END && (
            <Badge variant="error">INSCRIPCIONS TANCADES:<br/><small>{U.datetoHuman(CONFIG.SIGNIN_PITCH_END)}</small></Badge>
          )}
          <Card.Link href={ (Date.now() > CONFIG.SIGNIN_PITCH_START && Date.now() < CONFIG.SIGNIN_PITCH_END ) ? ROUTES.FORM_PITCH : undefined }>
            <Card.Img variant="top" src="/img/pitch.jpg"/>
          <Card.Body>
            <Card.Title>"Pitch" de projectes</Card.Title>
            <Card.Text>
              <small className='text-muted'>{MESSAGES.PITCH_FORM_DESC}</small>
            </Card.Text>

          </Card.Body>
          {(Date.now() > CONFIG.SIGNIN_PITCH_START && Date.now() < CONFIG.SIGNIN_PITCH_END ) && (
            <Badge bg="dark"><small>Inscripcions obertes fins:<br/> {U.datetoHuman(CONFIG.SIGNIN_PITCH_END)}</small></Badge>
            )}
          </Card.Link>
        </Card>
    </CardGroup>
  </Container>
);

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Landing);