import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import { PiCheckCircleFill, PiWarningDiamondFill } from 'react-icons/pi';
import { Button, ButtonGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LuFile, LuMailWarning, LuStar } from 'react-icons/lu';
import { FaCircleXmark } from 'react-icons/fa6';


let mailSignature = '\n🔴⚫ FIC-CAT ' + CONFIG.CURRENT_EDITION +'\n'
mailSignature += MESSAGES.FICCAT_DATE + '\n'
mailSignature += 'http://fic-cat.cat/' 

class FilmsListItem extends Component {
  constructor(props) {
    super(props);


    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
    }

    if (!this.state.film.mails) {
      const mails = {
        mailSeleccio: false,
        mailPremisses: false
      }
      this.state.film = {...this.state.film, mails}
    }

    if (localStorage.getItem('currentEdition') === null ) {
      this.Edition = CONFIG.CURRENT_EDITION;
    } else {
      this.Edition = localStorage.getItem('currentEdition');
    } 

    

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().id + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  updateMails(e) {


    const mailtype = e.target.name.split('-')[0];
    const filmUid = e.target.value;

    let data = {};
    let modified = false;

    switch(this.state.filmType) {
      case 'curts':
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.doc(filmUid);
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.music(filmUid);
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = 'JOVES';
      break;
      case 'pitch':
        this.state.API = this.props.firebase.pitch(filmUid);
        this.filmTypeName = 'PITCH';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.film(filmUid);
          this.filmTypeName = 'LLARGS';
        break;
    }

    this.state.API.once('value', snapshot => {
      
      if (snapshot) {
          data = snapshot.val();

          const mailTypeObject = { [mailtype] : JSON.parse(e.target.checked) };

          if (data.mails === undefined) {
            data.mails = {}
          }

          data.mails = { ...data.mails, ...mailTypeObject }
          
          modified = true;
          if (modified) {
            this.setState({ film: data })
            this.state.API.set(data);    
          }
      }
    })


  }

  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    } 
    } else {
      return false
    }
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }
  
  customizeMailBody(body, film, filmType) {
        
    let newBody = this.replaceAll(body, '$NAME', film.contactName)
    newBody = this.replaceAll(newBody, '$FILM_NAME', film.filmName)
    newBody = this.replaceAll(newBody, '$FILM_NAMUPPER', film.filmName.toUpperCase())
    newBody = this.replaceAll(newBody, '$FILM_TYPE', MESSAGES[filmType.replace('-','')])
    newBody = this.replaceAll(newBody, '$FILM_TYPUPPER', MESSAGES[filmType.replace('-','')].toUpperCase())
    newBody = this.replaceAll(newBody, '$FICCAT_EDITION', MESSAGES.FICCAT_EDITION)  
    newBody = this.replaceAll(newBody, '$FICCAT_DATE', MESSAGES.FICCAT_DATE)  
    newBody = this.replaceAll(newBody, '$FICCAT_YEAR', CONFIG.CURRENT_EDITION)
    newBody = this.replaceAll(newBody, '$FICCAT_JOVE_SESSION', MESSAGES.JOVE_DATE)
    newBody = this.replaceAll(newBody, '$FICCAT_JOVE_LIMIT_DATE', U.datetoHuman(CONFIG.MATERIAL_YOUNG_END, 'large'))
    newBody = this.replaceAll(newBody, '$FICCAT_PRESS', MESSAGES.FICCAT_PRESS)
    newBody = this.replaceAll(newBody, '$FICCAT_GALA', MESSAGES.FICCAT_GALA)
    if (film.filmSchoolLevel) {
     newBody = this.replaceAll(newBody, '$FICCAT_LEVELSCHOOL', film.filmSchoolLevel.replace('primaria','Primària').replace('secundaria','Secundària'))
    }
    return  encodeURIComponent(newBody)
  }

  materialMissing(film, filmType) {
    let body = ""
    if (filmType.toUpperCase() === 'JOVE') {
      body += ( ((!film.filmLinkDownload || !film.filmLinkTrailer))  ? "Us confirmem que tenim tot el material necessari per a la projecció:\n": '') 
      body += ( (!film.filmLinkDownload  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç de descàrrega per a la projecció\n') 
    } else {
      body += ( ((!film.filmLinkDownload || !film.filmLinkTrailer))  ? "Necessitem abans del "+ CONFIG.MATERIAL_MOVIE_DATE.toLocaleDateString() +" els arxius per a la projecció i promoció:\n": '') 
      body += ( (!film.filmLinkDownload  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç de descàrrega per a la projecció (Per enviar copies en format físic DCP poseu-vos en contacte amb nosaltres i us facilitarem una adreça o passarem a recollir-les.)\n') 
    }
    if (filmType.toUpperCase() !== 'JOVE') {
    body += ( (!film.filmLinkTrailer  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç tràiler en streaming\n') 
    body += ( (!film.isImageReady  ? "  - 🔵 Revisar:": '  - ✅ Revisat:') + ' Imatges pel catàleg i el web\n') 
    } else {
      body += '  - ✅ Revisat: Imatges pel catàleg i el web\n'
    }
    body += ( (film.acceptOnline !== false && (!film.acceptOnlineSigned || !film.acceptOnlineDownload))  ? "\n\nPARTICIPAR EN EL PREMI EN LÍNIA\nSi hi voleu participar necessitem abans del "+ CONFIG.MATERIAL_ONLINE_DATE.toLocaleDateString() +" la següent documentació i material (opcional):\n": '') 
    body += ( (!film.acceptOnlineSigned  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Cessió de drets en línia\n')
    body += ( (!film.acceptOnlineDownload  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç del fitxer per a 3CAT\n\n')  
    return body; 
  }

  DatetoHuman (date) {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('ca-CA', {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  sendMailJoves(film, filmType) {
    const subject = '[' + MESSAGES[filmType.replace('-','')] + ' '+ CONFIG.ACTIVE_EDITION +'] Premisses i enviaments de materials';
    const linkToMaterial = window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + this.Edition  + '/' + film.uid; 
    const separator = '____________________________________________________________\n\n';
    // const body = 'Please do not abuse "𝗯𝗼𝗹𝗱", "u̲n̲d̲e̲r̲l̲i̲n̲e̲", "𝘪𝘵𝘢𝘭𝘪𝘤" and other mathematical notations glyphs for ❝formatting❞.\nThanks! ' + linkToMaterial
    let body = ""
    body += "Benvolgut, benvolguda,\n\n"
    body += "El Concurs Jove del FIC-CAT $FICCAT_YEAR ja és aquí! Aquest any demanem que compliu les següents premisses:\n\n"
    body += " - " + MESSAGES.JOVE_REQUIREMENTS_1 + "\n"
    if (MESSAGES.JOVE_REQUIREMENTS_2 !== '') {
    body += " - " + MESSAGES.JOVE_REQUIREMENTS_2 + "\n"
    }
    if (MESSAGES.JOVE_REQUIREMENTS_3 !== '') {
      body += " - " + MESSAGES.JOVE_REQUIREMENTS_3 + "\n"
    }
    body += "\nRECORDEU:\n"
    body += " - Durada màxima 3 minuts (crèdits inclosos)\n"
    body += " - En català\n"
    body += " - De temàtica, gènere i modalitat lliures\n"
    body += "\nREGISTRAR L'OBRA AMB LA PLATAFORMA D'ENVIAMENTS (Oberta fins $FICCAT_JOVE_LIMIT_DATE):\n"
    body += 'Podeu accedir amb el vostre correu i número de telèfon de la persona de contacte que va omplir el formulari en el següent enllaç:\n\n'
    body += '  - 🔗 ' + linkToMaterial + '\n\n'
    body += "INFORMACIÓ NECESSÀRIA PER REGISTRAR L'OBRA A LA PLATAFORMA\n"
    body += "La plataforma d'enviaments estarà oberta fins $FICCAT_JOVE_LIMIT_DATE i haureu d'omplir la següent informació:\n\n"
    body += " - 3 fotogrames/imatges\n"
    body += " - Dades de l'obra (Títol, sinopsi, durada, gènere...)\n"
    body += " - Fitxa tècnica (Direcció, producció, guió i intèrprets principals)\n"
    body += " - Arxiu en alta qualitat (Enllaç de descàrrega de google drive, wetransfer o similar)\n\n"
    body += "Totes les produccions participants seran projectades $FICCAT_JOVE_SESSION al Teatre del Casino Municipal de Roda de Berà. Per això, us demanem que no les pengeu a cap plataforma ni xarxes socials, almenys fins a l'endemà de la seva projecció. Posterior a la projecció, nosaltres farem pública una llista de reproducció amb totes les obres.\n\n"
    body += "Si teniu qualsevol dubte o comentari, podeu trucar al 649519104 o 618858677 o escriure un correu a programacio@fic-cat.cat. Us respondrem de seguida.\n\n"
    body += "Gràcies per formar part del FIC-CAT $FICCAT_YEAR, i molta sort amb aquesta experiència!"
    return 'mailto:' + film.contactEmail + ';' + film['contactEmail-1'] + ';' + film['contactEmail-2'] + ';' + film['contactEmail-3'] + '?subject=' + subject + '&cc=programacio@fic-cat.cat&body=' + this.customizeMailBody(body, film, filmType) ;
  }
  sendMailSelection(film, filmType) {
    const subject = '[S.O. ' + MESSAGES[filmType.replace('-','')] + ' '+ CONFIG.ACTIVE_EDITION +'] ' + film.filmName;
    const linkToMaterial = window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + this.Edition  + '/' + film.uid; 
    const separator = '____________________________________________________________\n\n';
    // const body = 'Please do not abuse "𝗯𝗼𝗹𝗱", "u̲n̲d̲e̲r̲l̲i̲n̲e̲", "𝘪𝘵𝘢𝘭𝘪𝘤" and other mathematical notations glyphs for ❝formatting❞.\nThanks! ' + linkToMaterial
    let body = ""

    body += "Enhorabona $NAME,\n\n"
    body += "🥇 S.O. $FILM_TYPUPPER: $FILM_NAMUPPER\n"
    body += "En nom del Comitè de Selecció del Festival Internacional de Cinema en Català, és un plaer comunicar-vos que la vostra producció compleix les bases i ha estat seleccionada per formar part de la Selecció Oficial $FILM_TYPE de la $FICCAT_EDITION edició del Festival $FICCAT_DATE.\n\n"
    if (filmType.toUpperCase() !== 'JOVE') {
    body += "🔴 IMPORTANT: NO FEU PÚBLICA LA SELECCIÓ ENCARA.\n Tota la Selecció Oficial i el programa de projeccions es faran públics més endavant $FICCAT_PRESS.\n\n"
    }
    body += "PREMIS I CATEGORIES EN LES QUALS PARTICIPEU:\n";
    if (filmType.toUpperCase() === 'CENTRES-EDUCATIUS') {
      body+= "  - Millor producció de Centres educatius ($FICCAT_LEVELSCHOOL).\n"
    } else {
      body += "  - Millor producció de $FILM_TYPE\n";
    }
    if (film.isTarragona && filmType.toUpperCase() !== 'JOVE') {
      body += "  - Millor producció de Comarques Tarragonines\n";
    }
    body += "  - Premi del públic assistent\n"
    body += "  - Premi del públic en línia (opcional)\n"
    body += separator;
    body += 'MATERIAL NECESSARI:\n'
    body += this.materialMissing(film, filmType);
    body += 'ACCEDIU AL GESTOR DE MATERIALS PER FER-NOS ARRIBAR EL MATERIAL PENDENT:\n'
    body += 'Per accedir heu de fer servir el correu i el telèfon de la persona de contacte que va omplir el formulari i fer servir el següent enllaç:\n'
    body += '  - 🔗 ' + linkToMaterial + '\n\n'
    body += "Resolució de dubtes respecte a l'enviament de materials o de l'aplicació de gestió de materials: programacio@fic-cat.cat\n"
    body += separator;
    if (filmType.toUpperCase() !== 'JOVE') {
       body += "ASSISTÈNCIA A LA PROJECCIÓ + COL·LOQUI:\n"
    } else {
       body += "ASSISTÈNCIA A LA PROJECCIÓ + PRESENTACIÓ:\n"
    }
    if (film.session) {
      body += "📅 " + film.session.name + ": " + this.DatetoHuman(film.session.date) + " a les "+ film.session.time + "\n📍 " + CONFIG.LOCATION[film.session.location.split("-")[0]].name + ": " + CONFIG.LOCATION[film.session.location.split("-")[0]].address + "\n🔗 " + CONFIG.LOCATION[film.session.location.split("-")[0]].url + "\n\n"
    }
    

    switch (filmType.toUpperCase()) {
      default:
      case "CENTRES-EDUCATIUS":
        body += "Ens agradaria molt comptar amb la vostra presència el dia de la projecció per poder fer la presentació de la producció. Si us plau us preguem que confirmeu assistència posant-vos en contacte amb la vostra responsable de logística.\n"
      break;
      case "VIDEOCLIPS":
        body += "La projecció de Videoclips serà a la nit després de la pel·lícula d’inauguració. Serà una projecció molt festiva a l’aire lliure a la plaça on es troben els food-trucks i després tindrem sessió de DJ. Ens agradaria molt comptar amb la vostra presència el dia de la projecció per poder fer la presentació de la producció, si us plau us preguem que confirmeu assistència. Indiqueu els noms de les persones que assistiran, la vinculació amb la producció, e-mail i telèfon de contacte.\n"
      break;
      case "DOCUMENTALS":
      case "LLARGS":
        body += "Si us plau, us preguem que confirmeu assistència. Indiqueu els noms de les persones que assistiran, la vinculació amb la producció, e-mail i telèfon de contacte.\n"
        if (filmType==='LLARGS') {
          body += "\nD’altra banda, durant la projecció de la pel·lícula, ens agradaria convidar-vos a sopar mentre el públic veu la projecció. Per tot això, necessitarem saber, com més aviat millor la vostra assistencia així com si algú de vosaltres necessita allotjament per a aquella nit. Per motius de disponibilitat, us podem oferir aprox. fins a 4 allotjaments dobles + esmorzar\n"
        }
      break;
    }

    body += "\nASSISTÈNCIA A L'ENTREGA DE PREMIS:\n"
    body += "📅 Gala de Cloenda: $FICCAT_GALA\n📍 " + CONFIG.LOCATION[0].name + ": " + CONFIG.LOCATION[0].address + "\n\🔗 " + CONFIG.LOCATION[0].url + "\n\n"
    switch (filmType.toUpperCase()) {
      case "JOVE":
        body += "Màxim de 2 INVITACIONS amb seient reservat. Si venen més persones, hauran d’adquirir les entrades gratuïtes de públic general.\n"
      break;
      default:
        body += "Màxim de 4 INVITACIONS amb seient reservat. Si venen més persones, hauran d’adquirir les entrades gratuïtes de públic general.\n"
      break;
    }
    if (filmType.toUpperCase() !== 'CENTRES-EDUCATIUS' && filmType !== 'JOVE') {

    body += "\nASSISTÈNCIA A LA RODA DE PREMSA + VERMUT:\n"
        body += "📅 Gala de Cloenda: $FICCAT_PRESS\n📍 " + CONFIG.LOCATION[4].name + ": " + CONFIG.LOCATION[4].address + "\n\🔗 " + CONFIG.LOCATION[4].url + "\n\n"
    body += "Acompanyeu-nos durant l'anunci de la programació completa del festival d’enguany i algunes sorpreses més. En finalitzar la presentació podrem gaudir d'un Vermut per gentilesa de Family Gins.\n"
  }
    body += "\nCOM CONFIRMAR ASSISTÈNCIA:\n"
    body += "Per confirmar assistència a qualsevol dels actes, si us plau contacteu amb la persona responsable de la vostra logística i indiqueu les dades requerides per a cada esdeveniment:\n\n"
    if (film.session) {
      body += "👤" + CONFIG.LOGISTIC[film.session.logistic.split("-")[0]].name + "\n✉ " + CONFIG.LOGISTIC[film.session.logistic.split("-")[0]].mail + "\n📞 " + CONFIG.LOGISTIC[film.session.logistic.split("-")[0]].phone + "\n";
    }  else {
      body += "\n✉ logistica@fic-cat.cat\n"
    }
    body += separator;
    if (filmType !== 'JOVE') {
      body += "De nou, aprofitem per felicitar-vos; ha estat una selecció veritablement competitiva i estem encantats de poder comptar amb la vostra producció.\n\n"
    } else {
      body += "De nou, aprofitem per felicitar-vos. Estem encantats de poder comptar amb la vostra producció.\n\n"
    }
    body += "Salut i Cinema!\n"
    body += "Comitè de Selecció del FIC-CAT\n"
    body += mailSignature;
    return 'mailto:' + film.contactEmail + ';' + film['contactEmail-1'] + ';' + film['contactEmail-2'] + ';' + film['contactEmail-3'] + '?subject=' + subject + '&cc=programacio@fic-cat.cat&body=' + this.customizeMailBody(body, film, filmType) ;
  }
  

  sendMailMaterial(film, filmType) {
    const subject = '[REQUERIT] Material pendent: ' + film.filmName;
    const linkToMaterial = window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + this.Edition  + '/' + film.uid; 
    // const body = 'Please do not abuse "𝗯𝗼𝗹𝗱", "u̲n̲d̲e̲r̲l̲i̲n̲e̲", "𝘪𝘵𝘢𝘭𝘪𝘤" and other mathematical notations glyphs for ❝formatting❞.\nThanks! ' + linkToMaterial
    let body   = "Hola $NAME,\n\n"
    body      += "Aquest correu és un recordatori del fet que tenim pendent rebre material per la teva part per a la producció '$FILM_NAME'. \n"
    body += '\nNO  RESPONGUIS AQUEST CORREU... fes servir el següent enllaç:\n\n🔗 ' + linkToMaterial + '\n\n'
    body += 'MATERIAL NECESSARI:\n'
    body += this.materialMissing(film, filmType);
    body += mailSignature;
    return 'mailto:' + film.contactEmail + '?subject=' + subject + '&cc=programacio@fic-cat.cat&body=' + this.customizeMailBody(body, film, filmType);
  }

  render () {
    const { film, userFilm, filmType } = this.state
    const currentYear = new Date().getFullYear();
    return (
      <>
        { (( U.isUserContentManager() && film.isSelectable ) || (U.isUserProvider() && film.isOnline )) && (
        <Col className='table-list' xs={12}>
          <Row>
            <Col xs={12} sm={12} lg={(U.isUserProvider() ? 3 : 2)}>
              <div className={ ((film.isInfoReady !== true || film.isImageReady !== true || film.isDownloaded !== true || film.isOnline !== true) ? 'bg-light' : 'bg-success') + ' --item p-2'}>
              <h5>PRODUCCIÓ</h5>
              <Row>
                <Col>
                  <strong>
                    { U.isUserAdmin() ? (
                      <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid }>{film.filmName === 'Sense títol' ? film.contactName : film.filmName}</a>
                      ) : (
                      <a href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid }>{film.filmName}</a>
                    )}
                  </strong>
                  <br/>
                  <span className='text-muted'>de {film.filmDirector ? film.filmDirector : film.contactName } 
                      { film.filmSchool && (
                          <>
                            , de <strong>{film.filmSchool}</strong> <small className='text-muted'>({film.filmSchoolLevel})</small><br/> per {film.filmDirector}<br />
                          </>
                        ) || (
                          <>
                              { film.filmBand && (
                                <>
                                  ,Videoclip de <strong>{film.filmBand}</strong> per&nbsp;
                                </>
                              )}
                              { film.producerCompany && film.producerCompany !== '.' && (
                                <>
                                  <span className='text-muted'>, produit per {film.producerCompany}</span><br />
                                </>
                              )}
                            
                          </>
                        )}
                        </span>
                </Col>
              </Row>
              </div>
            </Col>
            <Col xs={6} sm={6} lg={(U.isUserProvider() ? 3 : 2)}>
              <div className={ ((film.isInfoReady && film.isImageReady) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>INFORMACIÓ</h5>
                    {film.isImageReady ? (<span className='text-success'><PiCheckCircleFill /></span> ) : ( (film.imageCover || film.imageFrame1) ? (<span className='text-warning'><PiWarningDiamondFill /></span>) : (<span className='text-dark'><FaCircleXmark /></span>)) } Imatges
                    <br/>{film.isInfoReady ? (<span className='text-success'><PiCheckCircleFill /></span> ) : ( (film.filmSynopsis && film.filmActoring) ? (<span className='text-warning'><PiWarningDiamondFill /></span>) : (<span className='text-dark'><FaCircleXmark /></span>)) } Fitxa
                    {U.isUserProvider() && (
                    <>
                    <br/>{film.acceptOnlineUploaded ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-dark'><FaCircleXmark /></span>) } Pujat al FTP
                    </>
                    )}
              </div>
            </Col>
            {!U.isUserProvider() && (
            <Col xs={6} sm={6} lg={(U.isUserProvider() ? 3 : 2)}>
              <div className={ ((film.isDownloaded) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>PROJECCIÓ</h5>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{film.session ?  film.session.name  + ':' : 'Aquesta producció no està'} <br />{film.session ? this.DatetoHuman(film.session.date) : 'associada a cap sessió'}</Tooltip>}
                      ><span>{film.session ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-dark'><FaCircleXmark /></span>)} Sessió</span>
                    </OverlayTrigger>
                    <br/>{film.isDownloaded ? (<span className='text-success'><PiCheckCircleFill /></span> ) : ( (film.filmLinkDownload ) ? (<span className='text-warning'><PiWarningDiamondFill /></span>) : (<span className='text-dark'><FaCircleXmark /></span>)) } Fitxer
              </div>
            </Col>
            )}
            <Col xs={6} sm={6} lg={(U.isUserProvider() ? 3 : 2)}>
              {U.isUserProvider() ? (
                <div className='bg-light --item p-2'>
                    <dl>
                        <dt>Gènere:</dt>
                        <dd>{film.filmGenre ? film.filmGenre : ''}</dd>
                        <dt>Codi ètic per edats:</dt>
                        <dd>{film.filmParentGuide ? film.filmParentGuide : 'pendent de revisió'}</dd>
                    </dl>
                </div>
              ): (
              <div className={ ((film.acceptOnlineSigned && film.acceptOnlineDownload && film.acceptOnlineUploaded) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>3CAT</h5>
                  {film.acceptOnlineSigned ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-dark'><FaCircleXmark /></span>)} Drets
                  <br/>{film.acceptOnlineDownload ? (<span className='text-success'><PiCheckCircleFill /></span> ) : ( (film.acceptOnlineSigned ) ? (<span className='text-warning'><PiWarningDiamondFill /></span>) : (<span className='text-dark'><FaCircleXmark /></span>)) } Fitxer
                  <br/>{film.acceptOnlineUploaded ? (<span className='text-success'><PiCheckCircleFill /></span> ) : ( (film.acceptOnlineDownload ) ? (<span className='text-warning'><PiWarningDiamondFill /></span>) : (<span className='text-dark'><FaCircleXmark /></span>)) } Pujat
                </div>
                )}
            </Col>

            <Col xs={6} sm={6} lg={(U.isUserProvider() ? 3 : 2)}>
              <div className={ ((film.acceptOnlineSigned && film.acceptOnlineDownload && film.acceptOnlineUploaded) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>MARCAR COM</h5>
                {U.isUserAdmin() && (

                    <>
                    { filmType === 'jove' && (
                    <>
                      <Form.Check
                        inline
                        label='Premisses comunicades'
                        id={'mailPremisses-' + film.uid }
                        name={'mailPremisses-' + film.uid }
                        onChange={this.updateMails.bind(this)}
                        type='switch'
                        value={film.uid}
                        defaultChecked={film.mails && film.mails.mailPremisses}
                      />
                    </>
                    )}
                    <br/>
                    <Form.Check
                      inline
                      label='Selecció comunicada'
                      id={'mailSeleccio-' + film.uid }
                      name={'mailSeleccio-' + film.uid }
                      onChange={this.updateMails.bind(this)}
                      type='switch'
                      disabled={!film.session}
                      value={film.uid}
                      defaultChecked={film.mails && film.mails.mailSeleccio}
                    />
                    </>
            )}

                    { (U.isUserProvider() || U.isUserAdmin) && (
                    <>
                    <Form.Check
                      inline
                      label='Rebut 3CAT'
                      id={'tresCatRebut-' + film.uid }
                      name={'tresCatRebut-' + film.uid }
                      onChange={this.updateMails.bind(this)}
                      type='switch'
                      disabled={!film.acceptOnlineUploaded}
                      value={film.uid}
                      defaultChecked={film.mails && film.mails.tresCatRebut}
                    />
                    </>
                  )}
                </div>
            </Col>
            { U.isUserAdmin() && (

            <Col xs={12} sm={12} lg={2}>
                <div className={ ((film.isInfoReady !== true || film.isImageReady !== true  || film.isDownloaded !== true || film.isOnline !== true) ? 'bg-light' : 'bg-success') + ' --item p-2'}>
                  <h5>ACCIONS</h5>
                  <>
                  <ButtonGroup>
                    { !film.mails.mailPremisses && filmType === 'jove' && (
                      <Button variant='outline-dark' href={ this.sendMailJoves(film, filmType.toUpperCase())} target='_blank'><LuFile /><small>Premisses</small></Button>
                    )}
                    { (!film.mails.mailSeleccio) && (
                      <Button variant={ !film.session ? 'outline-dark' : 'success' } href={ this.sendMailSelection(film, filmType.toUpperCase())} target='_blank' disabled={!film.session}><LuStar /><small>Selecció</small></Button>
                    )}
                    { film.mails.mailSeleccio && (film.isInfoReady !== true || film.isImageReady !== true  || film.isDownloaded !== true || film.isOnline !== true || film.isOnlineDownloaded !== true ) && (
                      <Button variant='outline-primary' href={ this.sendMailMaterial(film, filmType.toUpperCase())} target='_blank' disabled={!film.session}><LuMailWarning /><small>Recordatori</small></Button>
                    )}
                    </ButtonGroup>

                  
                  { !film.session && (
                    <p className='text-left'><span className='text-warning'><PiWarningDiamondFill /></span> <small>Falta asignar sessió.</small></p>
                  )}
                  </>
                  
                  </div>
            </Col>
                  )}

          </Row>
        </Col>
        )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsListItem);