import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import { Rating } from 'react-simple-star-rating'

import './index.scss';
import { Badge } from 'react-bootstrap';


class FilmsBadges extends Component {
  constructor(props) {
    super(props);


    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.state.userFilm = snapshotUserFilm.val();
      } else {
        this.state.userFilm = {
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        };
      }
    })
  }


  render () {
    const { film, userFilm, filmType } = this.state;
    const currentYear = new Date().getFullYear();
    return (
      <>
        <div className='FilmsBadges'>
            { (film.filmTarragona === 2 || film.isTarragona || ( (film.filmTarragona !== undefined && film.filmTarragona === true) || ( film.filmTarragona === undefined  && film.filmState && film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 )) ) && (
              <Badge pill bg='warning' className={film.isTarragona ? '' : 'disabled'} >COMARQUES TARRAGONINES</Badge>
            )}
            { (film.filmPremiere === 'Estrena' || film.isPremiere) && (
              <Badge pill bg='success' className={film.isPremiere ? '' : 'disabled'} >ESTRENA{film.isPremiereType ? ' ' + film.isPremiereType.toUpperCase() : ''}</Badge>
            )}
            { ((film.acceptOnline && film.isOnline !== false ) || film.isOnline === true) && (
              <Badge pill bg='danger' className={film.isOnline ? '' : 'disabled'} >3CAT</Badge>
            )}
            { ( film.isWoman) && (
              <Badge pill className={film.isWoman ? 'woman' : 'disabled'} >DONES</Badge>
            )}
            { ((film.filmFirst && film.isFirst !== false )|| film.isFirst === true) && (
              <Badge pill bg='info' className={film.isFirst ? '' : 'disabled'} >ÒPERA PRIMA</Badge>
            )}
          </div>
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsBadges);