import Axios from 'axios';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import Loading from '../Loading';
import FilmsListItem from '../FilmsListItem';
import SessionForm from '../SessionForm';

let SessionUid = ''
let SessionToGetFilms = ''

let edition = '';

if (localStorage.getItem('currentEdition') === null ) {
  edition = CONFIG.CURRENT_EDITION;
} else {
  edition = localStorage.getItem('currentEdition');
} 

class FilmsForm extends Component {

  constructor(props) {
    super(props);
    this.setRef = ref => {
      this.file = ref;
    }
    this.formData = {};

    SessionUid = props.match.params.uid ? props.match.params.uid : null;

    // Get User role and identify if it's admin or editor
    this.admin = U.isUserAdmin() ? true : false;
    this.editor = U.isUserEditor() ? true : false ;

    // Set default state
    this.state = {
      form: [],
      testSubmit: false, // TRUE or FALSE to make form simple to test
      success: false,
      validated: null,
      formStructure: [],
      data: {}
    };

    // Get form structure & current edition from CONFIG
    this.state.formStructure = CONFIG[this.state.type];


  }

  getFilmsSelected(categoryBySession) {

    let filmList = []
    let scopeKeeper = this;

    this.props.firebase.database().ref('films/' + edition + '/' + categoryBySession).orderByChild('isSelected').equalTo(true).on('value', snapshotFilm => {
          
      if (snapshotFilm && snapshotFilm.val()) {
          // GET ORIGINAL DATA

          snapshotFilm.forEach(function (childSnapshot) {

            let childSnapshotValue = childSnapshot.val();

            childSnapshotValue.uid = childSnapshot.key;
            childSnapshotValue.type = snapshotFilm.key;


            if (categoryBySession === 'CENTRES' ) {
              if (scopeKeeper.state.session.type === 'primaria' && childSnapshotValue.filmSchoolLevel === 'primaria') {
                filmList.push( childSnapshotValue )
              } else if (scopeKeeper.state.session.type === 'secundaria' && childSnapshotValue.filmSchoolLevel !== 'primaria') {
                filmList.push( childSnapshotValue )
              }
            } else {
              filmList.push( childSnapshotValue )
            }
          });
          
          this.setState({
            filmList: _.sortBy( filmList, 'sessionOrder' ),
          });

          // STORE DATA
          //this.state.API.set(data);

      }
    })
  }

  componentDidMount() {
    this.props.firebase.session(SessionUid).once('value', snapshot => {
      
      if (snapshot) {
        // convert messages list from snapshot
        this.setState({
          session: snapshot.val(),
        });

        let categories = []
        let filmList = []
        
        switch(this.state.session.type) {
          case "videoclips":
           //this.getFilmsSelected('VIDEOCLIPS');
           categories = ['VIDEOCLIPS']
          break;
          case "primaria":
          case "secundaria":
            //this.getFilmsSelected('CENTRES');
            categories = ['CENTRES']
          break;
          case "joves":
            //this.getFilmsSelected('JOVES');
            categories = ['JOVES']
          break;
          case "replica":
            //this.getFilmsSelected('JOVES');
            categories = ['CENTRES','CURTS','DOCS','LLARGS','JOVES','VIDEOCLIPS']
          break;
          case "standard":
          default:
            categories = ['CURTS','DOCS','LLARGS']            
          break;
        }

        SessionToGetFilms = this.state.session && this.state.session.type === 'replica' ? this.state.session.session.uid : SessionUid

        categories.map((category) => {
          this.props.firebase.database().ref('films/' + edition + '/' + category).orderByChild('sessionUid').equalTo(SessionToGetFilms).on('value', snapshotFilm => {
      
            if (snapshotFilm && snapshotFilm.val()) {
                // GET ORIGINAL DATA

                snapshotFilm.forEach(function (childSnapshot) {
                  let childSnapshotValue = childSnapshot.val();
                  childSnapshotValue.uid = childSnapshot.key;
                  childSnapshotValue.type = snapshotFilm.key;
                  filmList.push( childSnapshotValue )
                });
                
                
                this.setState({
                  filmList: _.sortBy( filmList, 'sessionOrder' ),
                });
    
            }
          })
        }) 

        

      }
    })
    
  }

  sendForm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: 'validated'});

      // GET ORIGINAL DATA
      let data = this.state.session;
      

    if (form.checkValidity() === false) {
      U.scrollToFirstError(e);
    } else {

        // OVERWRITE THE VALUES WITH THE ONES IN THE FORM
        data.name = form.elements['name'].value;
        data.date = form.elements['date'].value;
        data.time = form.elements['time'].value;
        data.location = form.elements['location'].value;
        data.logistic = form.elements['logistic'].value;
        data.type = form.elements['type'].value;
        if (data.type === 'replica') {
          data.session = {
            date: form.elements['sessionDate'].value,
            uid: form.elements['sessionTime'].value
          };

        } else {
          data.session = {}
        }

        
        // STORE DATA
        this.props.firebase.session(SessionUid).set(data);
        this.setState({ success: true });
        this.componentDidMount()
        setTimeout( () => { this.setState({ success: false }) }, "3000" )
    }
      
  }

  successMessage() {

    return (
      <Container>
        <Alert variant='success'>
          <FaCheckCircle /> Canvis guardats
        </Alert>
      </Container>
    );
  }


  contactInfoForm(){
    const {session, filmList} = this.state;
    return (
      <>
          { session ? (
            <Card bg="light" className='p-3 pb-2 mb-4'>
              <SessionForm session={session} />
        
          </Card>
          
          ) : (
            <Loading />
          )}
        </>
    )
  }

  render () {
    const {session, filmList} = this.state;
    return (
      <Container>
        <>
        { this.state.success === true && ( 
          this.successMessage()
        )}
          <div>
                <h1 className='my-4'> {U.isUserAdmin() && ('Editar')} Sessió <strong className='text-primary'>{edition}</strong></h1>
                <Row>
                  <Col sm={12} md={5} lg={4}>
                    <Form noValidate validated={ this.state.validated } id="formFilm" onSubmit={this.sendForm.bind(this)} ref='contactForm' >
                      {/* Render CONTACT info form if is defined in form structure */}
                        { this.contactInfoForm() }
                    </Form>
                  </Col>
                  { this.state.filmList && (
                  <Col sm={12} md={7} lg={8}> 
                    <Row className='film-list'>
                        { this.state.filmList.map((film,filmKey) =>(
                      <Col key={film.uid} xs={12} sm={12} lg={12}>
                        <FilmsListItem film={film} filmType={ROUTES['ITEM_' + film.type]} variant="wide" />
                      </Col>
                      ))}
                      </Row>
                  </Col>
                  )}
                </Row>
          </div>
        </>
      </Container>
    )
  }
}



export default compose(
  withFirebase
)(FilmsForm);
