import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import ListGroup from 'react-bootstrap/ListGroup'


import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import {
  VictoryPie,
  VictoryTheme,
  VictoryLabel,
  VictoryChart,
  VictoryBar
} from "victory";

let filmType = '';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    this.state = {
      type: null,
      API: null,
      filmList: undefined,
      filmListTotal: 0,
      totalFilms: 0,
      totalFilmsCategories: [],
      filmStates: {},
    }

  }
  getFilms(films, value ) {
    let filmsSelectable = [];
    if (films !== undefined && films !== null) {
      for ( var k = 0; k < films.length; k++) {
          filmsSelectable.push(films[k])
      }
    }
    return filmsSelectable;
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== undefined && films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }
  getFilmsByIsSelected( films, value ) {
    let filmsSelectable = [];
    if (films !== undefined && films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelected === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }
  getFilms( films, value ) {
    let filmsSelectable = [];
    if (films !== undefined && films !== null) {
      for ( var k = 0; k < films.length; k++) {
          filmsSelectable.push(films[k])
      }
    }
    return filmsSelectable;
  }
  getFilmsDetailCount( filmsVar, filmsDetailVar ) {
    let films = filmsVar !== 'youngs' ? this.getFilms(this.state[filmsVar], true) : this.getFilmsByIsSelectable(this.state[filmsVar], true);
    let filmsDetails = [];
    let detailsPosition = []
    if (films !== undefined) {
      for ( var k = 0; k < films.length; k++) {
        if (detailsPosition[films[k][filmsDetailVar]] !== undefined) {
          filmsDetails[detailsPosition[films[k][filmsDetailVar]]]['y']++
        } else {
          let length = filmsDetails.push({
            x: films[k][filmsDetailVar] === undefined ? 'Sense resposta' :  films[k][filmsDetailVar] ,
            y: 1
          })
        detailsPosition[films[k][filmsDetailVar]] = length - 1;

        }
        
      }
    }

    return  _.sortBy(filmsDetails, 'y').reverse();
  }
  componentDidMount() {
    this.UpdateFilmList(this.props.firebase.films(), 'films');
    this.UpdateFilmList(this.props.firebase.docs(), 'docs');
    this.UpdateFilmList(this.props.firebase.shorts(), 'shorts');
    this.UpdateFilmList(this.props.firebase.musics(), 'musics');
    this.UpdateFilmList(this.props.firebase.schools(), 'schools');
    this.UpdateFilmList(this.props.firebase.youngs(), 'youngs');
    this.UpdateFilmList(this.props.firebase.pitchs(), 'pitchs');

  }
  renderFilmStats(films, key, messageKey) {
    const filmsVar = key;
    const watched  = this.state[key + 'Watched'];
    const States  = this.state[key + 'States'];
    const FilmGenre  = this.state[key + 'FilmGenre'];
    const DirectorGenre  = this.state[key + 'DirectorGenre'];
    const ProducerGenre  = this.state[key + 'ProducerGenre'];
    const WriterGenre  = this.state[key + 'WriterGenre'];
    const totalFilms  = this.state[key + 'Total'];
    let PieData = []
    if (filmsVar !== 'youngs') {
      PieData = [
        { x: ( ((this.getFilmsByIsSelectable(films, true).length) - watched) > 0 ? ((this.getFilmsByIsSelectable(films, true).length) - watched) : ' ' ) , y: ((this.getFilmsByIsSelectable(films, true).length) - watched), fill: 'red' },
        { x: watched > 0 ? watched  : ' ' , y: watched, fill: 'green' },
        { x: ( ((this.getFilms(films, true).length) - (this.getFilmsByIsSelectable(films, true).length)) > 0 ? ((this.getFilms(films, true).length) - (this.getFilmsByIsSelectable(films, true).length)) : ' ' ) , y: ((this.getFilms(films, true).length) - (this.getFilmsByIsSelectable(films, true).length)), fill: 'brown' },
      ]
    } else {
      PieData = [
        { x: ( ((this.getFilmsByIsSelected(films, true).length) - watched) > 0 ? ((this.getFilmsByIsSelected(films, true).length)) : ' ' ) , y: ((this.getFilmsByIsSelectable(films, true).length)), fill: 'green' },
        { x: ( ((this.getFilmsByIsSelectable(films, true).length) - (this.getFilmsByIsSelected(films, true).length)) > 0 ? ((this.getFilmsByIsSelectable(films, true).length) - (this.getFilmsByIsSelected(films, true).length)) : ' ' ) , y: ((this.getFilmsByIsSelectable(films, true).length) - (this.getFilmsByIsSelected(films, true).length)), fill: 'brown' },
      ]
    }
    return (
    <Row>
      <h2>{MESSAGES[messageKey]}</h2>
      <Col sm lg={3}>
        <svg viewBox="0 0 400 400">
          <VictoryPie
            theme={VictoryTheme}
            standalone={false}
            width={400}
            height={400}
            labels={false}
            innerRadius={68}
            labelRadius={95}
            data={PieData}
            style={{
              data: {
                  fill: ({datum}) => datum.fill
              },
          }}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 50 }}
            x={200}
            y={200}
            text={(totalFilms)}
           />
        </svg>
        <dl className='stats-legend'>
              {filmsVar !== 'youngs' ? (
              <>
                <dt className='green'></dt>
                <dd>Evaluats: {watched}</dd>
                <dt className='red'></dt>
                <dd>Pendents: {((this.getFilmsByIsSelectable(films, true).length) - watched) }</dd>

                <dt className='brown'></dt>
                <dd>No aprobats: {((this.getFilms(films, true).length) - (this.getFilmsByIsSelectable(films, true).length))}</dd>
                </>
              ) : (
                <>
                <dt className='green'></dt>
                <dd>Han presentant curt: {(this.getFilmsByIsSelected(films, true).length)}</dd>
                <dt className='red'></dt>
                <dd>No han presentat curt: {((this.getFilmsByIsSelectable(films, true).length) - (this.getFilmsByIsSelected(films, true).length)) }</dd>
                </>
              )}
           </dl>
      </Col>
      { States && (
      <Col xs={6} sm>
         <div className='pb-3'>
        <h4>Provincia</h4>
            {States.map((state, index) => (
              <span key={index} className={ state.x ==='Tarragona' ? 'text-warning font-weight-bold' : 'text-muted' }>
                {state.x}: {state.y}<br />
              </span>
            ))}
            </div>
      </Col>
      )}
      { FilmGenre && (
      <Col xs={6} sm>
        <div className='pb-3'>
          <h4>Gènere</h4>
            {FilmGenre.map((genre, index) => (
              <span key={index} className={ (genre.x === 'Dona' || genre.x === 'No Binari') ? 'text-woman font-weight-bold' : 'text-muted' }>
                {genre.x}: {genre.y}<br />
              </span>
            ))}
            </div>
      </Col>
      )}
      { DirectorGenre && (
      <Col xs={6} sm>
        <div className='pb-3'>
        <h4>Direcció</h4>
            {DirectorGenre.map((genre, index) => (
              <span key={index} className={ (genre.x === 'Dona' || genre.x === 'No Binari') ? 'text-woman font-weight-bold' : 'text-muted' }>
                {genre.x}: {genre.y}<br />
              </span>
            ))}
            </div>
      </Col>
      )}
      { ProducerGenre && (
      <Col xs={6} sm>
        <div className='pb-3'>
        <h4>Producció</h4>
            {ProducerGenre.map((genre, index) => (
              <span key={index} className={ (genre.x === 'Dona' || genre.x === 'No Binari') ? 'text-woman font-weight-bold' : 'text-muted' }>
                {genre.x}: {genre.y}<br />
              </span>
            ))}
            </div>
      </Col>
      )}
      { WriterGenre && (
      <Col xs={6} sm>
        <div className='pb-3'>
        <h4>Guió</h4>
            {WriterGenre.map((genre, index) => (
              <span key={index} className={ (genre.x === 'Dona' || genre.x === 'No Binari') ? 'text-woman font-weight-bold' : 'text-muted' }>
                {genre.x}: {genre.y}<br />
              </span>
            ))}
            </div>
      </Col>
      )}
    </Row>
    )
  }
  UpdateFilmList(API, filmsVar) {

      const scopeKeeper = this;
      
      this.setState({ loading: true });    
  
      API.on('value', snapshot => {
        const filmObject = snapshot.val();
        if (filmObject) {
          const filmList = Object.keys(filmObject).map(key => ({
            ...filmObject[key],
            uid: key,
          }));
          // convert messages list from snapshot
  
          let filmListFiltered = {};
          switch(window.location.pathname.split('/')[3]) {
            case 'secundaria':
              filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' ) } );
            break;
            case 'primaria':
              filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
            break;
            default:
              filmListFiltered = filmList;
            break;
          }
  
          this.setState({
            [filmsVar]: filmListFiltered.reverse(),
            loading: false,
          });

          this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/').once('value', snapshotUserRanks => {
            if (snapshotUserRanks.val()) {
  
              const userRanks = Object.entries(snapshotUserRanks.val()).map((e) => ( { [e[0]]: e[1] } ));
  
              let myArrayFiltered = filmListFiltered.filter((el) => {
                return userRanks.some((f) => {
                  return Object.keys(f)[0] === el.uid;
                });
              });

              myArrayFiltered = _.filter( myArrayFiltered, function(film) { return (film.isSelectable) } )


              scopeKeeper.setState({ 
                [filmsVar + 'Watched']: myArrayFiltered.length,
                [filmsVar + 'States']: scopeKeeper.getFilmsDetailCount(filmsVar, 'filmState'),
                [filmsVar + 'FilmGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'filmGenre'),
                [filmsVar + 'DirectorGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'filmDirectorGenre'),
                [filmsVar + 'ProducerGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'producerGenre'),
                [filmsVar + 'WriterGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'writerGenre'),
                [filmsVar + 'Total']: ( filmsVar === 'youngs' ? scopeKeeper.getFilmsByIsSelectable(filmListFiltered, true).length : scopeKeeper.getFilms(filmListFiltered, true).length ),
               })


               if (Array.isArray(scopeKeeper.state.totalFilmsCategories)) {
                scopeKeeper.state.totalFilmsCategories.push(filmsVar)
                if(scopeKeeper.state.totalFilmsCategories.indexOf(filmsVar) !== -1 && filmsVar !== 'pitchs') {
                  if (filmsVar === 'youngs') {
                    scopeKeeper.setState({
                      totalFilms: scopeKeeper.state.totalFilms + scopeKeeper.getFilmsByIsSelectable(filmListFiltered, true).length,
                    })
                  } else {
                    scopeKeeper.setState({
                      totalFilms: scopeKeeper.state.totalFilms + filmListFiltered.length,
                    })  
                  }
                }
              }
            } else {
              scopeKeeper.setState({ 
                [filmsVar + 'Watched']: 0,
                [filmsVar + 'States']: scopeKeeper.getFilmsDetailCount(filmsVar, 'filmState'),
                [filmsVar + 'FilmGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'filmGenre'),
                [filmsVar + 'DirectorGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'filmDirectorGenre'),
                [filmsVar + 'ProducerGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'producerGenre'),
                [filmsVar + 'WriterGenre']: scopeKeeper.getFilmsDetailCount(filmsVar, 'writerGenre'),
                [filmsVar + 'Total']: ( filmsVar === 'youngs' ? scopeKeeper.getFilmsByIsSelectable(filmListFiltered, true).length : scopeKeeper.getFilms(filmListFiltered, true).length ),
                totalFilms: scopeKeeper.state.totalFilms + filmListFiltered.length,
               })
            }
          })
        } else {
          this.setState({ [filmsVar]: null, loading: false });
        }
      });
    }
  welcome() {
    const { films, docs, shorts, schools, youngs, pitchs, musics, totalFilms } = this.state;
    return (
      <>
        <Container>
            { !U.isUserCommittee() &&(
            <Row>
              <Col className='mb-3'>
              <h1>Benvingut/da</h1>
              <p>Si us plau, selecciona una opció del menú superior per veure les produccions per categoria.</p>
                <img src='/img/crispeta-bg.jpg' style={{ maxWidth: '100%', border: '4px solid #000'}} />
              </Col>
            </Row>
            )}
            { U.isUserCommittee() && (
            <>
              { !U.isUserPitch() && (
              <>
              Total d'inscripcions: <Badge bg="success">{totalFilms} produccions</Badge> + <Badge bg="success">{this.getFilms(pitchs, true).length} projectes</Badge>
              <br/><br/>
              { this.renderFilmStats(films, 'films', 'LLARGS') }
              <hr />
              { this.renderFilmStats(docs, 'docs', 'DOCS') }
              <hr />
              { this.renderFilmStats(shorts, 'shorts', 'CURTS') }
              <hr />
              { this.renderFilmStats(musics, 'musics', 'VIDEOCLIPS') }
              <hr />
              { this.renderFilmStats(schools, 'schools', 'CENTRES') }
              <hr />
              { this.renderFilmStats(youngs, 'youngs', 'JOVES') }
              <hr />
              </>
              )}
              { this.renderFilmStats(pitchs, 'pitchs', 'PITCH') }
            </>
            )}
        </Container>

      </>
    )
  }
  render () {


    return (
     <>
        {this.welcome()}
     </>
    )
  }
}

const condition = () => { return  U.isUserCommittee()}

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Dashboard);