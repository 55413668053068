import Axios from 'axios';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import FileUploader from '../FileUploader';
import FilmsFormInfo from './info';
import FilmsFormCast from './cast';
import FilmsFormContact from './contact';
import { collection, addDoc } from "firebase/firestore"; 

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { css, cx } from '@emotion/react'
import { Card, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { PiBuilding, PiCake, PiCertificate, PiEnvelope, PiIdentificationBadge, PiLink, PiLock, PiPhone, PiSuitcase, PiUser, PiWarningDiamondFill } from 'react-icons/pi';

class FilmsForm extends Component {

  constructor(props) {
    super(props);
    this.setRef = ref => {
      this.file = ref;
    }
    this.formData = {};


    // Set default state
    this.state = {
      form: [],
      testSubmit: false, // TRUE or FALSE to make form simple to test
      success: false,
      premiere: true,
      country: 'Espanya',
      state: undefined,
      category: undefined,
      validated: null,
      overRideAccess: false,
      formStructure: [],
      data: {},
    };

    const code = this.props.location.search.split('?code=')[1];



    if (code !== undefined && CONFIG.SIGNIN_FILMS_CODE.indexOf(code) !== -1) {
      this.state.overRideAccess = true;
    } 

    // Identify category based on URL match
    const handle = this.props.match.path
    switch(handle) {
      case '/curts':
          this.state.type = 'CURTS';
      break;
      case '/documentals':
          this.state.type = 'DOCS';
      break;
      case '/centres-educatius':
          this.state.type = 'CENTRES';
      break;
      case '/videoclips':
          this.state.type = 'VIDEOCLIPS';
      break;
      case '/jove':
        this.state.type = 'JOVES';
      break;
      case '/pitch':
        this.state.type = 'PITCH';
      break;
      case '/llargs':
      default:
          this.state.type = 'LLARGS';
      break;
    }

    // Get form structure & current edition from CONFIG
    this.state.formStructure = CONFIG[this.state.type];
    this.Edition = 'films/' + CONFIG.CURRENT_EDITION + '/' + this.state.type;
    this.state.basesLink = this.state.type === 'JOVES' || this.state.type === 'PITCH' ? '/pdf/bases_' + CONFIG.CURRENT_EDITION +'_' + this.state.type + '.pdf' : '/pdf/bases_' + CONFIG.CURRENT_EDITION + '.pdf'

  }

  sendForm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: 'validated'});

    let params = {};

    if (form.checkValidity() === false) {

      U.scrollToFirstError(e);

    } else {
      let NewDate = new Date(Date.now());
      params = {
        filmSignDate: NewDate,
        filmCategory: this.inputFilmCategory ? this.inputFilmCategory.value : 'Sense categoría',

        // FILM INFO
        filmName: form.elements["filmName"] ? form.elements["filmName"].value : 'Sense títol',
        filmBand: form.elements["filmBand"] ? form.elements["filmBand"].value : null,
        filmSynopsis: form.elements["filmSynopsis"] ? form.elements["filmSynopsis"].value : null,
        filmLength: form.elements["filmLength"] ? form.elements["filmLength"].value : null,
        filmYear: form.elements["filmYear"] ? form.elements["filmYear"].value : null,
        filmCategory: form.elements["filmCategory"] ? form.elements["filmCategory"].value : null,
        filmGenre: form.elements["filmGenre"] ? form.elements["filmGenre"].value : null,
        filmCountry: form.elements["filmCountry"] ? form.elements["filmCountry"].value : null,
        filmState: form.elements["filmState"] ? form.elements["filmState"] .value : null,
        filmCity: form.elements["filmCity"] ? form.elements["filmCity"].value : null,
        filmFirst: form.elements["filmFirst"] ? form.elements["filmFirst"].value : false,
        isFirst: form.elements["filmFirst"] ? (form.elements["filmFirst"].value === 'true' ? true : false) : false,
        filmPremiere: form.elements["filmPremiere"] ? (form.elements["filmPremiere"].value === 'true' ? true : false ) : null,
        isPremiere: form.elements["filmPremiere"] ? (form.elements["filmPremiere"].value === 'Estrena' ? true : false ) : null,
        filmFestivals: form.elements["filmFestivals"] ? form.elements["filmFestivals"].value : null,
        filmLangCAT: form.elements["filmLangCAT"] ? form.elements["filmLangCAT"].checked : null,
        filmLangES: form.elements["filmLangES"] ? form.elements["filmLangES"].checked : null,
        filmLangEN: form.elements["filmLangEN"] ? form.elements["filmLangEN"].checked : null,
        filmLangOT: form.elements["filmLangOT"] ? form.elements["filmLangOT"].checked : null,
        filmLanguage: ( form.elements["filmLangCAT"] ? ((form.elements["filmLangCAT"].checked ? 'Català' : '') + (form.elements["filmLangES"].checked ? ', Castellà' : '') + (form.elements["filmLangEN"].checked ? ', Anglès' : '') + (form.elements["filmLangOT"].checked? ', Altres.' : '')) : '' ),
        filmTarragonaStaff: form.elements["filmTarragonaStaff"] ? form.elements["filmTarragonaStaff"].checked : false,
        filmTarragonaLocation: form.elements["filmTarragonaLocation"] ? form.elements["filmTarragonaLocation"].checked : false,
        filmTarragona: (form.elements["filmTarragonaStaff"]  ? form.elements["filmTarragonaStaff"].checked : false) + (form.elements["filmTarragonaLocation"] ? form.elements["filmTarragonaLocation"].checked : false),
        isTarragona: ( form.elements["filmTarragonaStaff"] && form.elements["filmTarragonaLocation"] ? ( ( (form.elements["filmTarragonaStaff"].checked ? true : false ) + (form.elements["filmTarragonaLocation"].checked ? true : false) ) === 2 ? true : false ) : false ),

        // CAST
        filmSchoolTutor: form.elements["filmSchoolTutor"] ? form.elements["filmSchoolTutor"].value : null,                
        filmDirector: form.elements["filmDirector"] ? form.elements["filmDirector"].value : null,
        filmDirectorGenre: form.elements["filmDirectorGenre"] ? form.elements["filmDirectorGenre"].value : null,
        producerName: form.elements["producerName"] ? form.elements["producerName"].value : null,
        producerGenre: form.elements["producerGenre"] ? form.elements["producerGenre"].value : null,
        producerCompany: form.elements["producerCompany"] ? form.elements["producerCompany"].value : null,
        producerSite: form.elements["producerSite"] ? form.elements["producerSite"].value : null,
        writerName: form.elements["writerName"] ? form.elements["writerName"].value : null,
        writerGenre: form.elements["writerGenre"] ? form.elements["writerGenre"].value : null,
        filmActoring: form.elements["filmActoring"] ? form.elements["filmActoring"].value : null,

        filmLink: this.inputFilmLink ? this.inputFilmLink.value : null,
        filmPass: this.inputFilmPass ? this.inputFilmPass.value : null,
        imageCover: this.state.imageCover ? this.state.imageCover : null,
        pdfFile: this.state.pdfFile ? this.state.pdfFile : null,
        imageFrame1: this.state.imageFrame1 ? this.state.imageFrame1 : null,
        imageFrame2: this.state.imageFrame2 ? this.state.imageFrame2 : null,
        imageFrame3: this.state.imageFrame3 ? this.state.imageFrame3 : null,

        acceptInfo: this.inputAcceptInfo && this.inputAcceptInfo.checked ? 'Si' : 'No',
        acceptLegal: this.inputAcceptLegal && this.inputAcceptLegal.checked ? 'Si' : 'No',
        acceptLegalOnline: this.inputAcceptLegalOnline && this.inputAcceptLegalOnline.checked ? 'Si' : 'No',
        acceptOnline: this.inputAcceptOnline && this.inputAcceptOnline.checked ? 'Si' : 'No',
        acceptOwn: this.inputAcceptOwn && this.inputAcceptOwn.checked ? 'Si' : 'No',
        message: this.inputMessage ? this.inputMessage.value : null,
        isWoman: ((this.inputFilmDirectorGenre && this.inputFilmDirectorGenre.value === 'Dona' ? true : false) || (this.inputWriterGenre && this.inputWriterGenre.value === 'Dona' ? true : false)  || (this.inputProducerGenre && this.inputProducerGenre.value === 'Dona' ? true : false) )
      };


       //  CONTACT INFO
       CONFIG.CONTACT_M.map((m) => {
    
        params['contactName' + m] = form.elements["contactName" + m] ? form.elements["contactName" + m].value : null;
        params['contactRole' + m] = form.elements["contactRole" + m] ? form.elements["contactRole" + m].value : null
        params['contactEmail' + m] = form.elements["contactEmail" + m] ? form.elements["contactEmail" + m].value : null
        params['contactPhone' + m] = form.elements["contactPhone" + m] ? form.elements["contactPhone" + m].value : null
        params['contactNIF' + m] = form.elements["contactNIF" + m] ? form.elements["contactNIF" + m].value : null
        params['contactBirth' + m] = form.elements["contactBirth" + m] ? form.elements["contactBirth" + m].value : null
        params['filmSchool' + m] = form.elements["filmSchool" + m] ? form.elements["filmSchool" + m].value : null
        params['filmSchoolLevel' + m] = form.elements["filmSchoolLevel" + m] ? form.elements["filmSchoolLevel" + m].value : null
      })

      // STORE CONST FOR EMAIL
      const to = params.contactEmail;
      const toUs = "administradors@fic-cat.cat";
      const subject = params.filmName;
      const name = params.contactName;
      const film = params.filmName;
      const filmDate = params.filmSignDate;
      const filmCategory = MESSAGES[this.state.type];

      
      // WRITE ON DATABASE AND SEND EMAIL IF SUCCESS
      this.props.firebase.database().ref(this.Edition).push(params).then((params) => {
        
      const filmLink =  window.location.origin + ROUTES['LIST_'  + this.state.type];

        this.setState({ success: true });

        
        // EMAIL TO THE USER
        Axios.get(CONFIG.SEND_EMAIL_URL, {
          params: {
            to: to,
            subject: subject,
            name: name,
            film: film,
            filmCategory: filmCategory,
            filmDate: filmDate
          },
        });

        // EMAIL TO ADMINS
        Axios.get(CONFIG.SEND_EMAIL_URL, {
          params: {
            to: toUs,
            subject: subject,
            name: name,
            film: film,
            filmCategory: filmCategory,
            filmLink: filmLink,
            filmDate: filmDate
          },
        });
        
      }).catch(() => {
        this.setState({ success: false })
      });
    }
  }

  successMessage() {
    return (
      <Container>
        <Col sm={8} style={{ margin: '0 auto'}}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm>
                  <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
                  <h5 className="card-title">Gràcies per la teva inscripció!</h5>
                  { this.state.type === 'JOVES' && (
                    <p>Recorda que el concurs tindrà lloc el proper {MESSAGES.FICCAT_YOUNG}.<br/><br/> Per qualsevol dubte consulta les <a href={this.state.basesLink} target='blank'>bases</a>.</p>
                  )}
                  { this.state.type !== 'JOVES' && (
                  <p className="card-text">Hem rebut correctament la teva producció. Ens posarem en contacte amb tu per correu electrònic per comunicar el resultat de la selecció.</p>
                  )}
                  <Button href={ROUTES.LANDING} variant="success" className='mt-4 mb-4'>Fer una altra inscripció</Button>
                </Col>
                <Col sm>
                <img className='card-img-bottom' src={"/img/" + this.state.type.toLowerCase()  + ".jpg"} alt="FIC-CAT" />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    );
  }

  imageUploadForm (){

    const handleUploadSuccess = (url, imageField) => {
      this.setState({
        [imageField]: url
      });
    }
    
    return (
      <div className='pb-4'>
        { this.state.type === 'PITCH' && (
            <Alert variant='info'>
              <FaInfoCircle />
              <h3>Important</h3>
              <p>
                Tota la documentació ha d’estar redactada en llengua catalana, en un únic document, i ha de complir els <a href={'pdf/bases_2025_PITCH_requisits.pdf'} target='_blank'>requisits del dossier de projecte</a>.</p>
              </Alert>
          )}
          <h2 className='mt-4 mb-4'>Pujada d'arxius <small>Segueix les indicacions requerides de format de fitxer i restriccions de tamanys.</small></h2>
          <Card bg="light" className='p-3 pb-2 mb-4'>
            <Row>
            
            { this.state.formStructure.indexOf('pdfFile') !== -1 && (
              <Col sm={6} className="pb-2">
                <dl>
                  <dt>
                    <strong className='text-dark font-weight-normal'><h5 className='mb-0 pb-0'>Dossier del projecte:</h5></strong><small className='text-muted'>(Un únic document en format PDF i de tamany inferior a 25 Mb.)</small>
                  </dt>
                  <dd>
                    <FileUploader
                    hidden
                    accept="pdf"
                    imageField='pdfFile'
                    maxSize={25000000}
                    minSize={10000}
                     text="Afegir dossier"
                    onUploadSuccess={handleUploadSuccess}
                  />
                  </dd>
                </dl>
              </Col>
            )}  
            { this.state.formStructure.indexOf('imageFile') !== -1 && (
              <Col sm={6} className="pb-2">
                <dl>
                  <dt>
                    <strong className='text-dark font-weight-normal'><h5 className='mb-0 pb-0'>Imatge principal de referència:</h5> </strong><small className='text-muted'>(Només una. Format JPG o similar. Mínim 100KB, máxim 5MB.)</small>
                  </dt>
                  <dd>
                    <FileUploader
                    hidden
                    accept="image/*"
                    imageField='imageCover'
                    maxSize={5000000}
                    minSize={100000}
                    onUploadSuccess={handleUploadSuccess}
                  />
                  </dd>
                </dl>
              </Col>
            )}
            { this.state.formStructure.indexOf('imageCover') !== -1 && (
              <Col sm={6} className="pb-2">
                <dl>
                  <dt>
                    <strong className='text-dark font-weight-normal'><h5 className='mb-0 pb-0'>Cartell en alta resolució:</h5></strong><small className='text-muted'>(Format JPG o similar. Mínim 1MB, máxim 20MB.)</small>
                  </dt>
                  <dd>
                    <FileUploader
                    hidden
                    accept="image/*"
                    imageField='imageCover'
                    maxSize={20000000}
                    minSize={1000000}
                    onUploadSuccess={handleUploadSuccess}
                  />
                  </dd>
                </dl>
              </Col>
            )}
            { this.state.formStructure.indexOf('imageFrame1') !== -1 && (
              <Col sm={6} className="pb-2">
                <dl>
                  <dt>
                    <strong className='text-dark font-weight-normal'><h5 className='mb-0 pb-0'>Fotograma principal: </h5></strong><small className='text-muted'>(Format JPG o similar. Mínim 100KB, máxim 5MB.)</small>
                  </dt>
                  <dd>
                    <FileUploader
                    hidden
                    accept="image/*"
                    imageField='imageFrame1'
                    maxSize={5000000}
                    minSize={100000}
                    onUploadSuccess={handleUploadSuccess}
                  />
                  </dd>
                </dl>
              </Col>
            )}
            { this.state.formStructure.indexOf('imageFrame2') !== -1 && (
              <Col sm={6} className="pb-2">
                <dl>
                  <dt>
                  <strong className='text-dark font-weight-normal'><h5 className='mb-0 pb-0'>Fotograma 2:</h5></strong><small className='text-muted'>(Format JPG o similar. Mínim 100KB, máxim 5MB.)</small>
                  </dt>
                  <dd>
                    <FileUploader
                    hidden
                    accept="image/*"
                    imageField='imageFrame2'
                    maxSize={5000000}
                    minSize={100000}
                    onUploadSuccess={handleUploadSuccess}
                  />
                  </dd>
                </dl>
              </Col>
            )}
            { this.state.formStructure.indexOf('imageFrame3') !== -1 && (
              <Col sm={6} className="pb-2">
                <dl>
                  <dt>
                    <strong className='text-dark font-weight-normal'><h5 className='mb-0 pb-0'>Fotograma 3:</h5> </strong><small className='text-muted'>(Format JPG o similar. Mínim 100KB, máxim 5MB.)</small>
                  </dt>
                  <dd>
                    <FileUploader
                    hidden
                    accept="image/*"
                    imageField='imageFrame3'
                    maxSize={5000000}
                    minSize={100000}
                    onUploadSuccess={handleUploadSuccess}
                  />
                  </dd>
                </dl>
              </Col>
            )}
          </Row>
        </Card>
      </div>
    )
  } 


  filmLinkForm () {
    return (
      <>
        { (this.state.formStructure.indexOf('filmLink') !== -1 &&
          this.state.formStructure.indexOf('filmPass') !== -1 ) && (
        <div className='pb-3'>
          <h2 className='mt-4 pb-2'>Dades de visionat <small>Enllaç en streaming per a la selecció oficial.</small></h2>
          <Alert variant='info'>
          <FaInfoCircle />
            <p><strong>L'enllaç no ha de caducar</strong> i ha d'estar disponible per a la seva visualització en línia (streaming) com a mínim fins al començament de l'edició del FIC-CAT {MESSAGES.FICCAT_DATE}.</p>
            <p className='mb-0'><small>Nota: En cas que la vostra producció entri a la Selecció Oficial, es requerirà un format de projecció DCP o Fitxer digital.</small></p>
          </Alert>
        <Card bg="light" className='p-3 pb-2 mb-4'>
            <Form.Group  className="mb-3" controlId="filmLink">
              <Form.Label>Enllaç vídeo <small className='text-muted'>(Vimeo, Youtube o Google Drive)</small></Form.Label>
              <InputGroup hasValidation>
              <InputGroup.Text><PiLink /></InputGroup.Text>
              <Form.Control
                required
                type='text'
                name="filmLink"
                placeholder='Ex: http://vimeo.com/exemple' 
                ref={filmLink => this.inputFilmLink = filmLink}
              />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
            <Form.Group  className="mb-3" controlId="filmPass">
              <Form.Label>Contrasenya <small className='text-muted'>(Si no en té, escriu: 'no')</small></Form.Label>
              <InputGroup hasValidation>
              <InputGroup.Text><PiLock /></InputGroup.Text>
              <Form.Control
                required
                type='text'
                name="filmPass"
                ref={filmPass => this.inputFilmPass = filmPass}
                placeholder='Ex: 1234'
              />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
          </Card>
        </div>
      )}
      </>
    )
  } 

  legalInfoForm(){

    const handleCheckboxChange = (e) => {
      if ( e.target.checked ) {
        this.value = 'Si'
      } else {
        this.value = 'No'
      }
    };

    return (
      <div>
        <h2 className='mt-4 mb-4 pb-2'>Acceptació de bases i cessió de drets <small>Es requereix acceptar les bases i ser propietari/a dels drets.</small></h2>
        <Card bg="light" className='p-3 pb-2 mb-4'>
        <div className='form-group'>
          <Form.Check>
            <Form.Check.Input
              required
              id="acceptLegal"
              onChange={handleCheckboxChange}
              ref={acceptLegal => this.inputAcceptLegal = acceptLegal}
            />
            <Form.Check.Label htmlFor="acceptLegal"><strong className='text-info'>Requerit:</strong> <strong>Accepto <a href={this.state.basesLink} target='blank'>les bases</a></strong> de la present edició del FIC-CAT i certifico que la informació proveïda en aquest formulari és certa.</Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        { this.state.formStructure.indexOf('acceptOwn') !== -1 && (
        <div className='form-group'>
        <Form.Check>
            <Form.Check.Input
              id="acceptOwn"
              required
              onChange={handleCheckboxChange}
              ref={acceptOwn => this.inputAcceptOwn = acceptOwn}
            />
            <Form.Check.Label htmlFor="acceptOwn"><strong className='text-info'>Requerit:</strong> <strong>Sóc propietari/a dels drets d'exhibició</strong> d'aquesta obra i/o estic autoritzat/da per a la seva distribució i exhibició. </Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        )}
        { this.state.formStructure.indexOf('acceptOnline') !== -1 && (
        <div className='form-group'>
          <Form.Check>
            <Form.Check.Input
              id="acceptOnline"
              onChange={handleCheckboxChange}
              ref={acceptOnline => this.inputAcceptOnline = acceptOnline}
            />
            <Form.Check.Label htmlFor="acceptOnline"><strong className='text-dark'>Opcional:</strong> Estic interessat/da en optar al <strong>premi del públic online</strong> que requerirà posar la meva producció online durant els dies del Festival {MESSAGES.FICCAT_DATE} en una plataforma com <strong>3CAT, LAXARXA+</strong> o similar. <small>(Et contactarem per concretar i signar la cessió de drets en línia)</small></Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        )}
        </Card>
      </div>
    )
  } 

  render () {
    const { formStructure, premiere, country, state, success, overRideAccess } = this.state;
    // const success = true;
    return (
      <div>
        
        { ( (CONFIG.SIGNIN_FILMS && this.state.type !== 'PITCH' && this.state.type !== 'JOVES') || ( CONFIG.SIGNIN_PITCH && this.state.type === 'PITCH' ) || ( CONFIG.SIGNIN_YOUNG && this.state.type === 'JOVES' ) || (U.isUserContentManager()) || (overRideAccess === true) ) ? (
        <>
        { success === true && ( 
          this.successMessage()
        )}
        { success !== true && ( (this.state.type === 'JOVES' && CONFIG.SIGNIN_YOUNG) || (this.state.type === 'PITCH' && CONFIG.SIGNIN_PITCH) || ((this.state.type !== 'JOVES' && this.state.type !== 'PITCH') && (CONFIG.SIGNIN_FILMS || overRideAccess === true) ) ) ?  (
          <Container>
            <div className='row'>
              <div className='col-sm-12'>
                <h1 className='mt-4 mb-0'> Inscripció { MESSAGES[this.state.type] } <strong className='text-primary'>{CONFIG.CURRENT_EDITION}</strong></h1>
                <p className="text-muted pb-4">{ MESSAGES[this.state.type + '_FORM_DESC'] }</p>
                {/* Render IMAGE UPLOAD form if is defined in form structure */}
                { (this.state.formStructure.indexOf('imageCover') !== -1 ||
                  this.state.formStructure.indexOf('imageFile') !== -1 ||
                  this.state.formStructure.indexOf('pdfFile') !== -1 ||
                      this.state.formStructure.indexOf('imageFrame1') !== -1 ||
                      this.state.formStructure.indexOf('imageFrame2') !== -1 ||
                      this.state.formStructure.indexOf('imageFrame3') !== -1 ) && (
                  this.imageUploadForm()
                )}
                <Form noValidate validated={ this.state.validated } id="formFilm" onSubmit={this.sendForm.bind(this)} ref='contactForm' >
                  {/* Render CONTACT info form if is defined in form structure */}
                  { (this.state.formStructure.indexOf('contactName') !== -1 ||
                      this.state.formStructure.indexOf('contactRole') !== -1 ||
                      this.state.formStructure.indexOf('contactEmail') !== -1 ||
                      this.state.formStructure.indexOf('contactNIF') !== -1 ||
                      this.state.formStructure.indexOf('contactBirth') !== -1 ||
                      this.state.formStructure.indexOf('filmSchool') !== -1 ||
                      this.state.formStructure.indexOf('filmSchoolLevel') !== -1 ||
                      this.state.formStructure.indexOf('contactPhone') !== -1 ) && (
                        <div className='pb-4'>
                          <h2 className='mt-4 mb-4'>Persona de contacte principal <small>Totes les comunicacions seran enviades a la persona designada com a contacte principal.</small></h2>
                          <FilmsFormContact type={this.state.type} formStructure={formStructure} />
                        </div>
                  )}
                  { (this.state.formStructure.indexOf('contactName-1') !== -1 ||
                      this.state.formStructure.indexOf('contactRole-1') !== -1 ||
                      this.state.formStructure.indexOf('contactEmail-1') !== -1 ||
                      this.state.formStructure.indexOf('contactNIF-1') !== -1 ||
                      this.state.formStructure.indexOf('contactBirth-1') !== -1 ||
                      this.state.formStructure.indexOf('filmSchool-1') !== -1 ||
                      this.state.formStructure.indexOf('filmSchoolLevel-1') !== -1 ||
                      this.state.formStructure.indexOf('contactPhone-1') !== -1 ) && (
                        <div className='pb-4'>
                          <h2 className='mt-4 mb-4'>Persona de contacte secundària<small>Cada projecte inscrit pot tenir fins a dues persones associades.</small></h2>
                          <FilmsFormContact type={this.state.type} formStructure={formStructure} modifier="-1" />
                        </div>
                  )}
                  {/* Render FILM INFO form if is defined in form structure */}
                  { this.state.testSubmit !== true && (
                    <FilmsFormInfo type={this.state.type} formStructure={formStructure} premiere={premiere} country={country} state={state} />
                  )}
                  {/* Render FILM CAST form if is defined in form structure */}
                   { this.state.testSubmit !== true && (
                    <FilmsFormCast type={this.state.type} formStructure={formStructure} />
                  )}
                  {/* Render FILM LINK form if is defined in form structure */}
                  { this.state.testSubmit !== true && (
                    this.filmLinkForm()
                  )}
                  { this.legalInfoForm() }
                  <div className='form-group'>
                    <label htmlFor='message'>Vols afegir alguna informació o suggeriment?</label>
                    <textarea className='form-control' id='message' rows='3' ref={message => this.inputMessage = message}></textarea>
                  </div>
                  <button type='submit' className='btn btn-lg btn-success mb-4'><strong>Enviar inscripció</strong></button>
                </Form>
              </div>
            </div>
          </Container>
        ) : (
          <>
          {!success && (
            <Container>
              <Alert variant='warning'><PiWarningDiamondFill /> Inscripcions tancades</Alert>
            </Container>
          ) }
          </>
        )}
        </>
        ) : ( 
          <Container>
            <h2 className='mt-4 pb-2'>{MESSAGES.SIGIN_FALSE_TITLE}</h2>
            <Alert variant='warning' ><PiWarningDiamondFill /> {MESSAGES.SIGIN_FALSE_DESC}</Alert>
          </Container>
        )}
      </div>
    )
  }
}



export default compose(
  withFirebase
)(FilmsForm);
