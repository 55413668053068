import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';        // for authentication
import 'firebase/compat/storage';     // for storage
import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore';   // for cloud firestore
// import 'firebase/messaging';   // for cloud messaging
// import 'firebase/functions';   // for cloud functions

import * as CONFIG from '../../constants/config';
import * as U from '../../constants/utilities';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  token: process.env.APP_CHECK_DEBUG_TOKEN_FROM_CI,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};



class Firebase {
  constructor() {
    firebase.initializeApp(config);

    /* Helper */

    this.serverValue = firebase.database.ServerValue;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = firebase.auth();
    this.db = firebase.database();
    this.st = firebase.storage();
    this.fs = firebase.firestore();

    /* Social Sign In Method Provider */

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();

    this.authUser = U.authUser();

    if (localStorage.getItem('currentEdition') === null ) {
      this.currentEdition = CONFIG.CURRENT_EDITION;
    } else {
      this.currentEdition = localStorage.getItem('currentEdition');
    } 


  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);


  doSignInAnonymous = (auth) =>
    this.auth.signInAnonymously(auth);


  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => { this.auth.signOut(); U.forceUpdate() };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            if (authUser.deactivated === true) {
              this.auth.signOut()
            }

            next(authUser);
          });
      } else {
        fallback();
      }
    });

    

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** films API ***

  year = () => this.db.ref('films/' + this.currentEdition);

  session = uid => this.db.ref('films/' + this.currentEdition + `/SESSION/${uid}`);
  
  sessions = () => this.db.ref('films/').child( this.currentEdition).child(`SESSION`);

  doc = uid => this.db.ref('films/' + this.currentEdition + `/DOCS/${uid}`);
  docs = () => this.db.ref('films/' + this.currentEdition + `/DOCS`);

  film = uid => this.db.ref('films/' + this.currentEdition + `/LLARGS/${uid}`);
  films = () => this.db.ref('films/' + this.currentEdition + `/LLARGS`);

  short = uid => this.db.ref('films/' + this.currentEdition + `/CURTS/${uid}`);
  shorts = () => this.db.ref('films/' + this.currentEdition + `/CURTS`);

  school = uid => this.db.ref('films/' + this.currentEdition + `/CENTRES/${uid}`);
  schools = () => this.db.ref('films/' + this.currentEdition + `/CENTRES`);

  music = uid => this.db.ref('films/' + this.currentEdition + `/VIDEOCLIPS/${uid}`);
  musics = () => this.db.ref('films/' + this.currentEdition + `/VIDEOCLIPS`);

  young = uid => this.db.ref('films/' + this.currentEdition + `/JOVES/${uid}`);
  youngs = () => this.db.ref('films/' + this.currentEdition + `/JOVES`);

  pitch = uid => this.db.ref('films/' + this.currentEdition + `/PITCH/${uid}`);
  pitchs = () => this.db.ref('films/' + this.currentEdition + `/PITCH`);


  database = () => this.db;

  firestore = () => this.fs;

  storage = () => this.st;
}

export default Firebase;
