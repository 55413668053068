import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash'

import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as CONFIG from '../../constants/config';
import * as ROLES from '../../constants/roles';
import * as U from '../../constants/utilities';

import FilmsListItemDefault from '../FilmsListItem';
import FilmsListItemMails from '../FilmsListContacts';
import FilmsListItemStuff from '../FilmsListStuff';
import FilmsListItemPressNote from '../FilmsListPressNote';
import FilmsListFilters from '../FilmsListFilters';
import { Button, Card, Container } from 'react-bootstrap';
import { object } from 'underscore';
import Loading from '../Loading';

let filmType = '';
let view = '';
let FilmsListItem = FilmsListItemDefault;

class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    view = (props.match.params.view && (U.isUserCommittee() || U.isUserProvider())) ? (U.isUserProvider() ? 'material' : props.match.params.view) : 'normal';

    switch( view ) {
      case 'email':
        FilmsListItem = U.isUserCommittee() ? FilmsListItemMails : FilmsListItemDefault;
      break;
      case 'premsa':
        FilmsListItem = U.isUserContentManager() ? FilmsListItemPressNote : FilmsListItemDefault;
      break;
      case 'material':
        FilmsListItem = (U.isUserContentManager() || U.isUserProvider()) ? FilmsListItemStuff : FilmsListItemDefault;
      break;
      default:
        if (!U.isUserProvider()) {
          FilmsListItem = FilmsListItemDefault;
        } else {
          FilmsListItem = FilmsListItemStuff;
        }
    }

    this.state = {
      type: null,
    }

    
  }
  render () {
    return (
      <div style={{ padding: '20px 0 0'}}>
        
        <Films filmType={this.filmType} />
      </div>
    )
  }
}

class FilmList extends Component {
  constructor(props) {
    super(props);
    this.films = props.films;
    this.groups = props.groups;
    this.filmType = props.filmType ;
    switch(filmType) {
      case 'curts':
        this.filmTypeName = 'CURTS';
      break;
      case 'llargs':
      default:
        this.filmTypeName = 'LLARGS';
      break;
      case 'documentals':
        this.filmTypeName = 'DOCS';
      break;
      case 'pitch':
        this.filmTypeName = 'PITCH';
      break;
      case 'centres-educatius':
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.filmTypeName = 'JOVE';
      break;
    }
  }
  copyToClipboard (str) {
    function listener(e) {
      e.clipboardData.setData("text/html", str);
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    }
    const scopeKeeper = this;
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
    scopeKeeper.setState({clipboard: true});
    setTimeout(function(){
      scopeKeeper.setState({clipboard: false});
  }, 2000);
  };
  render() {
    const films = this.films;
    const groups = this.groups;
    const {clipboard} = this.state ? this.state : false;
    return (
      <>
      { ((U.isUserCommittee() )
      || U.isUserProvider()
      || ( this.filmType !== ROLES.JURY_YOUNG && this.filmType !== ROLES.JURY_SCHOOL &&  U.isUserJuryOficial() )
      || ( this.filmType === ROLES.JURY_SCHOOL &&  U.isUserJurySchool() )
      || ( this.filmType !== ROLES.JURY_YOUNG &&  U.isUserJuryYoung())) && ( 
        <> 
          { view === 'premsa' ? (
            <Container>
              <Card className='px-4 py-4'>
                <Button variant={ clipboard ? 'success' : 'primary' } onClick={() => this.copyToClipboard(document.getElementById('film-list').innerHTML)}>{ clipboard ? (<i>&#x2713;</i>) : (<i>&#x2398;</i>)} { clipboard ? 'Copiat' : 'Copiar al porta-retalls' }</Button>
                <div id='film-list' className='mt-4'>
                    <h2 style={{ color: '#BB271A', fontWeight: 'normal', fontVariant: 'normal', fontSize: '18.5px'}}>{ this.filmTypeName ? this.filmTypeName.charAt(0).toUpperCase() : ''}{ MESSAGES[this.filmTypeName] ? MESSAGES[this.filmTypeName].slice(1) : '' } ({films.length})</h2>
                    <ul>
                    {films.map((film, index) => (
                      <FilmsListItem key={films[index].uid} groups={groups} previousFilm={films[(index - 1)] ? films[(index - 1)] : films[index]} films={films} film={film} filmType={filmType} />
                    ))}
                    </ul>
                </div>
              </Card>
            </Container>
          ) : (
          <Row id='film-list' className='film-list' xs={1} md={view === 'normal' ? 3 : 1} lg={ view === 'normal' ? 4 : 1} >
            {films.map((film, index) => (
              <Col key={films[index].uid}>
                <FilmsListItem groups={groups} previousFilm={films[(index - 1)] ? films[(index - 1)] : films[index]} films={films} film={film} filmType={filmType} />
              </Col>
            ))}
          </Row>
          )}
        </>
    )}
    </>
    )
  }
} 

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalLength: 0,
      maxTotalLength: 0,
      totalPremiere: 0,
      totalOnline: 0,
      totalTarragona: 0,
      totalStates: {},
      totalTypes: {},
      maxChoiceNumber: 0,
      films: [],
      API: null,
      groups: [],
    };




    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.state.maxChoiceNumber = CONFIG.MAX_CURTS_NUM;
        this.state.maxTotalLength = CONFIG.MAX_CURTS;
        this.filmTypeName = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.maxChoiceNumber = CONFIG.MAX_LLARGS_NUM;
        this.state.API = this.props.firebase.films();
        this.state.maxTotalLength = CONFIG.MAX_LLARGS;
        this.filmTypeName = 'LLARGS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.state.maxChoiceNumber = CONFIG.MAX_DOCS_NUM;
        this.state.maxTotalLength = CONFIG.MAX_DOCS;
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.state.maxTotalLength = CONFIG.MAX_CENTRES;        
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.state.maxTotalLength = CONFIG.MAX_CURTS_VIDEOCLIPS;
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'pitch':
        this.state.API = this.props.firebase.pitchs();
        this.state.maxChoiceNumber = CONFIG.MAX_PITCH_NUM;
        this.filmTypeName = 'PITCH';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.filmTypeName = 'JOVES';
      break;
    }

  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }
  updateFilmList() {
    this.setState({ loading: true });

    let subGroup = window.location.pathname.split('/')[3];

    if (subGroup !== undefined) {
      this.state.maxTotalLength = CONFIG['MAX_CENTRES_' + subGroup.toUpperCase() ]
    }

    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        
        // GET USER RANK AND ATTACH IT TO THE ARRAY

        let scopeKeeper = this;
        let response = [];
        let userList = [];
        let tempUserList = [];
        let userListIds = [];
        let userTotal = 0;
        let totalLength = 0,
            totalPremiere = 0,
            totalWoman = 0,
            totalFirst = 0,
            totalTarragona = 0,
            totalOnline = 0,
            totalTypes = {},
            totalStates = {};

        // Get list of users
        scopeKeeper.props.firebase.users().once('value', snapshotUserList => {
          if (snapshotUserList.val()) {
            tempUserList = snapshotUserList.val()
            var keysUserList =  Object.keys(tempUserList); 
            var nObject = 0;
            for (var user in tempUserList) {
              if (tempUserList[user] !==  undefined) {
                if (tempUserList[user].roles !==  undefined) {
                  if (tempUserList[user].roles[ROLES.JURY] === ROLES.JURY_OFICIAL || tempUserList[user].roles[ROLES.JURY] === ROLES.JURY_SCHOOL || tempUserList[user].roles[ROLES.JURY] === ROLES.JURY_YOUNG) {
                    userList[keysUserList[nObject]] = tempUserList[user]
                  }
                }
              }
              nObject++;
            }
            userListIds = Object.keys(userList);
          } else {
            userList = []
          }
        
          // For each item on the film list...
          filmList.forEach( function(element, index, array) {
            
            element.userFilmRanks = [];
            element.userFilmScore = 0;
            element.userFilmPeopleNumber = 0;
            element.userFilmPeopleNames = '';

            let score = false;

              userListIds.forEach( function(userElement, userIndex, userArray) {  
                
                // Get the user opinion value
                scopeKeeper.props.firebase.database().ref(CONFIG.USER_NOTES + userElement + '/' + element.uid).once('value', snapshotUserFilm => {
                  if (snapshotUserFilm.val() && element) {
                    score = snapshotUserFilm.val().userFilmRank;
                  } else {
                    score = false
                  }
                  element.userFilmRanks[userElement] = {
                    name: userList[userElement].username,
                    score: score,
                    uid: userElement
                  }
                  if (score > 0 ) {
                    element.userFilmPeopleNumber++
                    if (element.userFilmPeopleNumber > userTotal) {
                      userTotal = element.userFilmPeopleNumber;
                    }
                    element.userFilmScore += score
                    element.userFilmRank = ( element.userFilmPeopleNumber <= 1 ? (element.userFilmScore / 2).toFixed(2) : (element.userFilmScore / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmScoreRank = ( element.userFilmPeopleNumber >= (Math.round(userTotal / 3) + 1) ? element.userFilmRank : (element.userFilmRank / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmPeopleNames += userList[userElement].username + ': ' + score + ' / '
                  }
                  
                  if (response.indexOf(element.uid) === -1) {

                    response.push(element.uid);

                    function timestrToSec(timestr) {
                      if (timestr && timestr.toString().indexOf(':') > 0) {
                          return Math.floor(timestr.split(':')[0]);
                      } else if (timestr) {
                        return Math.floor(timestr);
                      } else {
                        return 0;
                      }
                    }

                    // INCREASE TOTAL SELECTED DURATION
                    if (element.isSelected && element.isSelectable ) {


                      let realLength = element.filmLengthFixed ? element.filmLengthFixed : element.filmLength
                      
                      if ( subGroup === 'primaria' && (element.filmSchoolLevel === 'infantil' || element.filmSchoolLevel === 'primaria' )) {
                        totalLength = timestrToSec(totalLength) + timestrToSec(realLength);
                      } else if ( subGroup === 'secundaria' && (element.filmSchoolLevel === 'secundaria' || element.filmSchoolLevel === 'batxillerat' )) {
                        totalLength = timestrToSec(totalLength) + timestrToSec(realLength);
                      } else {
                        totalLength = timestrToSec(totalLength) + timestrToSec(realLength);
                      }



                    // INCREASE TOTAL PREMIERE COUNTER
                    if ( element.isPremiere ) {
                      totalPremiere++ 
                    }

                    if (element.isOnline){
                      totalOnline++
                    }

                    if (element.isWoman){
                      totalWoman++
                    }

                    if (element.isFirst){
                      totalFirst++
                    }

                    if (element.isTarragona){
                      totalTarragona++
                    } 



                      let State = element.filmState,
                          groupState = undefined;

                      if (element.filmState.toUpperCase().indexOf('TARRAGONA') !== -1) {
                        State = 'Tarragona'
                        groupState = 'Catalunya'
                      } else if (element.filmState.toUpperCase().indexOf('BARCELONA') !== -1 || element.filmState.toUpperCase().indexOf('BAIX LLOBREGAT') !== -1 ) {
                        State = 'Barcelona'
                        groupState = 'Catalunya'
                      } else if (element.filmState.toUpperCase().indexOf('GIRONA') !== -1) {
                        State = 'Girona'
                        groupState = 'Catalunya'
                      }  else if (element.filmState.toUpperCase().indexOf('LLEIDA') !== -1) {
                        State = 'LLeida'
                        groupState = 'Catalunya'
                      }  else if (element.filmState.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf('VALENCIA') !== -1) {
                        State = 'Valencia'
                        groupState = 'Pais Valencia'
                      }  else if (element.filmState.toUpperCase().indexOf('ALACANT') !== -1) {
                        State = 'Alacant'
                        groupState = 'Pais Valencia'
                      } else if (element.filmState.toUpperCase().indexOf('MALLORCA') !== -1 || element.filmState.toUpperCase().indexOf('BALEARS') !== -1) {
                        State = 'Mallorca'
                        groupState = 'Illes Balears'
                      }  else if (element.filmState.toUpperCase().indexOf('MENORCA') !== -1) {
                        State = 'Menorca'
                        groupState = 'Illes Balears'
                      }

                      if (groupState !== undefined ) {
                        if (totalStates[groupState] === undefined) {
                          totalStates[groupState] = {};
                          totalStates[groupState]['total'] = 0;
                        }
                        if (totalStates[groupState][State] === undefined) {
                          totalStates[groupState][State] = 0;
                        }
                        totalStates[groupState][State]++;
                        totalStates[groupState]['total']++;
                      } else {
                        if (totalStates[State] === undefined) {
                          totalStates[State] = {};
                          totalStates[State]['total'] = 0;
                        }
                        totalStates[State]['total']++;
                      }

                    // INCREASE SUBCATEGORIES COUNTER
                    if (filmType === 'curts' && (element.filmSchoolLevel === window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] === undefined)) {
                      if (totalTypes[element.filmCategory] === undefined) {
                        totalTypes[element.filmCategory] = 0;
                      }
                      totalTypes[element.filmCategory] = totalTypes[element.filmCategory] + 1;
                    }
                  }

                  }
              
                  if (response.length >= filmList.length ) {
                    let filmSortList =  _.filter( filmList, function(film) { return film.isSelected === true &&  film.isSelectable === true } );
                      filmSortList = _.sortBy( filmSortList, 'userFilmScoreRank' ).reverse()

                    let filmListFiltered = {};

                    // ONLY FOR SCHOOLS FILTER IF /secundaria or /primaria
                    switch(window.location.pathname.split('/')[3]) {
                      case 'secundaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' || film.filmSchoolLevel === 'altres' ) } );
                      break;
                      case 'primaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
                      break;
                      default:
                        filmListFiltered = filmSortList;
                      break;
                    }
                    
                    scopeKeeper.setState({
                      films: filmSortList = _.sortBy( filmListFiltered, 'filmName' ),
                      totalLength: totalLength,
                      totalPremiere: totalPremiere,
                      totalOnline: totalOnline,
                      totalStates: totalStates,
                      totalWoman: totalWoman,
                      totalFirst: totalFirst,
                      totalTarragona: totalTarragona,
                      totalTypes: totalTypes,
                      loading: false
                    });
                  }

                })
              }) 

              
          });
        }); 
        // convert messages list from snapshot
        
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
    }
    this.updateFilmList();
  }

  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading, totalLength, maxTotalLength, maxChoiceNumber, } = this.state;
    const groups = [0,0,0,0,0,0,0,0,0,0,0,0];
    return (
      <div>
        {films ? (
          <React.Fragment>
            { loading ? (
              <Loading />
            ) : (
            <Container>
              <Row>
                <Col sm>
                  <h2 className='my-2'>{ this.filmTypeName ? MESSAGES[this.filmTypeName].charAt(0).toUpperCase() : ''}{ MESSAGES[this.filmTypeName] ? MESSAGES[this.filmTypeName].slice(1) : ''}
                    <small>SELECCIÓ OFICIAL {CONFIG.ACTIVE_EDITION}</small>
                  </h2>
                  <FilmsListFilters urlBase={ ROUTES.CHOICE_CENTRES  } filmType={this.state.type} filters={['view','school'] } view={ view }/>
                </Col>
                <Col sm>
                <Container className='card bg-light pt-1 mb-3 text-center'>
                  <Row className="justify-content-md-center">
                    <Col xs={6} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>TOTAL:</small></dt>
                        <dd className="text-muted">
                          <span className='h3'>{this.state.films.length}</span>
                          { !U.isUserJury() && (
                           <small><br/>{maxChoiceNumber > 0 ? 'Max: ' + maxChoiceNumber : 'No Max.' }</small>
                          )}
                          </dd>
                      </dl>
                    </Col>
                    <Col xs={6} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Duració:</small></dt>
                        <dd className='text-muted'>
                          <span className='h3'>{totalLength}m</span>
                          { !U.isUserJury() && (
                             <small><br/>{maxTotalLength > 0 ? 'Max: ' + maxTotalLength + 'm' : 'No Max.' }</small>
                          )}
                        </dd>
                      </dl>
                    </Col>
                    <Col xs={6} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Estrenes:</small></dt>
                        <dd className="h1 text-success">{this.state.totalPremiere}</dd>
                      </dl>
                    </Col>
                    <Col xs={6} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Tarragona:</small></dt>
                        <dd className="h1 text-warning">{this.state.totalTarragona}</dd>
                      </dl>
                    </Col>
                    <Col xs={4} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Dones:</small></dt>
                        <dd className="h1 text-woman">{this.state.totalWoman}</dd>
                      </dl>
                    </Col>
                    <Col xs={4} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>O.Prima:</small></dt>
                        <dd className="h1 text-info">{this.state.totalFirst}</dd>
                      </dl>
                    </Col>
                    <Col xs={4} lg>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>3CAT:</small></dt>
                        <dd className="h1 text-danger">{this.state.totalOnline}</dd>
                      </dl>
                    </Col>
                  </Row>
                </Container>      
              </Col>
            </Row>
            <FilmList films={films} groups={groups} filmType={this.filmTypeName} />
          </Container>
        )}
          </React.Fragment>
        ) : (
          <Alert variant="warning">No hi ha cap producció seleccionada en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = () => { return !U.isUserAnonymous() };

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);