import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";


import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { PiWarningDiamondFill } from 'react-icons/pi';

class FilmsFormInfo extends Component {

  constructor(props) {
    super(props);

    if (props.film) {
      const film = props.film;
      this.state = {
        name: film.filmName ? (film.filmName === 'Sense títol' ? '' : film.filmName ) : '',
        band: film.filmBand ? film.filmBand : '',
        synopsis: film.filmSynopsis ? film.filmSynopsis : '',
        parentGuide: film.filmParentGuide ? film.filmParentGuide : '',
        year: film.filmYear ? film.filmYear : '',
        genre: film.filmGenre ? film.filmGenre : '',
        length: film.filmLength ? film.filmLength : '',
        premiere: film.filmPremiere ? film.filmPremiere : "",
        isPremiere: film.isPremiere ? film.isPremiere : (film.filmPremiere ? film.filmPremiere : false),
        festivals: film.filmFestivals ? film.filmFestivals : '',
        country: film.filmCountry ? film.filmCountry : "Espanya",
        state: film.filmState ? film.filmState : "",
        city: film.filmCity ? film.filmCity : "",
        first: film.filmFirst ? film.filmFirst : false,
        langCAT: film.filmLangCAT ? film.filmLangCAT : false,
        langES: film.filmLangES ? film.filmLangES : false,
        langEN: film.filmLangEN ? film.filmLangEN : false,
        langOT: film.filmLangOT ? film.filmLangOT : false,
        tarragonaStaff: film.filmTarragonaStaff ? film.filmTarragonaStaff : false,
        tarragonaLocation: film.filmTarragonaLocation ? film.filmTarragonaLocation : false,
      };
    } else {
      this.state = {
        name: props.name ? props.name: '',
        band: props.band ? props.band : '',
        synopsis: props.synopsis ? props.synopsis : '',
        parentGuide: props.parentGuide ? props.parentGuide : '',
        year: props.year ? props.year : '',
        genre: props.genre ? props.genre : '',
        length: props.length ? props.length : '',
        premiere: props.premiere ? props.premiere : '',
        isPremiere: props.premiere ? props.premiere : false,
        country: props.country ? props.country : "Espanya",
        state: props.state ? props.state : "",
        city: props.city ? props.city : "",
      };
    }

    // Set default state
    this.state.type =  props.type;
    this.state.formStructure = props.formStructure ? props.formStructure : [];

  }
  render() {
    const { formStructure, name, band, synopsis, parentGuide, length, year, category, genre, type, premiere, isPremiere, festivals, country, state, city, first, langCAT, langES, langEN, langOT, tarragonaStaff, tarragonaLocation } = this.state;
    return (
      <div className='pb-2'>
        <h2 className='mt-4 mb-4'>Dades de l'obra <small>Informació bàsica de la producció.</small></h2>
        <Card bg="light" className='p-3 pb-2 mb-4'>
          <Row>
          { formStructure.indexOf('filmName') !== -1 && (
            <Col>
              <Form.Group  className="mb-3" controlId="filmName">
              <Form.Label>Títol de la producció</Form.Label>
              <Form.Control
                required
                type='text'
                name="filmName"
                defaultValue={name}
                placeholder='Ex: La gran pel·lícula'
              />
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
            </Col>
          )}
          
          </Row>
          { formStructure.indexOf('filmBand') !== -1 && (
          <Row>
              <Form.Group  className="mb-3" controlId="filmBand">
              <Form.Label>Intèrpret(s) / Grup de l'obra musical</Form.Label>
              <Form.Control
                required
                type='text'
                name="filmBand"
                defaultValue={band}
                placeholder='Ex: La Trinca'
              />
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
          </Row>

          )}
          { formStructure.indexOf('filmSynopsis') !== -1 && (
          <Row>
              <Form.Group  className="mb-3" controlId="filmSynopsis">
              <Form.Label>Sinopsi (inferior a 350 caràcters)</Form.Label>
              <Form.Control
                as='textarea'
                required
                rows='3'
                name="filmSynopsis"
                defaultValue={synopsis}
                maxLength='350'
                placeholder='Ex: Un noi coneix a una noia i....'
              >
              </Form.Control>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
            </Row>
          )}
        { (formStructure.indexOf('filmParentGuide') !== -1) && (
          <Row>
            <Col>
              <Form.Group  className="mb-3" controlId="filmParentGuide">
                <Form.Label>Codi ètic per edats</Form.Label>
                <Form.Control as="select"
                  required
                  name="filmParentGuide"
                  defaultValue={parentGuide}
                >
                  <option value=''>Selecciona una opció...</option>
                  { CONFIG.PARENTGUIDE.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                </Form.Control>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
            </Row>
          )}
          <Row>
          { formStructure.indexOf('filmLength') !== -1 && (
          <Col lg={4}>
            <Form.Group  className="mb-3" controlId="filmLength">
              <Form.Label>Durada en minuts</Form.Label>
              <Form.Control
                required
                type='number'
                placeholder='Ex: 120'
                step="1"
                defaultValue={length}
                name="filmLength"
              />
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
          </Col>
          )}
          { formStructure.indexOf('filmYear') !== -1 && (
          <Col lg={4}>
            <Form.Group  className="mb-3" controlId="filmYear">
              <Form.Label>Any de producció <small className='text-muted'>(Màxima antiguitat: {CONFIG.CURRENT_EDITION-1})</small> </Form.Label>
              <Form.Control
                required
                type='number'
                defaultValue={year}
                placeholder={CONFIG.CURRENT_EDITION}
                min={(Math.round(CONFIG.CURRENT_EDITION)) - 1}
                max={Math.round(CONFIG.CURRENT_EDITION)}
                name="filmYear"
              />
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
          </Col>
          )}
          { (formStructure.indexOf('filmCategory') !== -1) && (
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmCategory">
                <Form.Label>Tipus de producció</Form.Label>
                <Form.Control as="select"
                  required
                  name="filmCategory"
                  defaultValue={category}
                >
                  <option value=''>Selecciona una opció...</option>
                  { CONFIG.CATEGORIES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                </Form.Control>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
          { (formStructure.indexOf('filmGenre') !== -1) && (
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmGenre">
                <Form.Label>Gènere</Form.Label>
                <Form.Control as="select"
                  required
                  name="filmGenre"
                  defaultValue={genre}
                >
                  <option value=''>Selecciona una opció...</option>
                  { CONFIG.GENRES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                  { type !== 'LLARGS' && (
                    <option value='Documental'>Documental</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
          </Row>
          <Row>
          { formStructure.indexOf('filmCountry') !== -1 && (
          <Col lg={4}>
            <Form.Group  className="mb-3" controlId="filmCountry">
              <Form.Label>País</Form.Label>
              <Form.Control as="select"
                required
                name="filmCountry"
                defaultValue={country}
                onChange={ (event) => { this.setState({ country: event.target.value }) } }
              >
                <option value=''>Selecciona una opció...</option>
                { CONFIG.COUNTRIES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
              </Form.Control>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
            </Form.Group>
          </Col>
          )}
          { formStructure.indexOf('filmState') !== -1 && country !== 'Espanya' && (
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmState">
                <Form.Label>Província</Form.Label> 
                <Form.Control
                  required
                  type='text'
                  name="filmState"
                  defaultValue={state}
                  placeholder='Ex: Tarragona'
                  onChange={ (event) => { this.setState({ state: event.target.value }) } }
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
          { formStructure.indexOf('filmState') !== -1 && country === 'Espanya' && (
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmState">
                <Form.Label>Província</Form.Label>
                <Form.Control as="select"
                  required
                  name="filmState"
                  defaultValue={state}
                  onChange={ (event) => { this.setState({ state: event.target.value }) } }
                >
                  <option value=''>Selecciona una opció...</option>
                  { CONFIG.SPAIN_STATES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                </Form.Control>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
            )}
          { formStructure.indexOf('filmCity') !== -1 && state === 'Tarragona' && (
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmCity">
                <Form.Label>Localitat</Form.Label>
                <Form.Control as="select"
                  required
                  name="filmCity"
                  defaultValue={city}
                >
                  <option value=''>Selecciona una opció...</option>
                  { CONFIG.TARRAGONA_CITIES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                </Form.Control>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
            )}
          { formStructure.indexOf('filmCity') !== -1 && state !== 'Tarragona' && (
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmCity">
                <Form.Label>Localitat</Form.Label>
                <Form.Control
                  required
                  name="filmCity"
                  type='text'
                  defaultValue={city}
                  placeholder='Ex: Roda de Berà'
                />
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
          </Row>
          <Row>
          { formStructure.indexOf('filmFirst') !== -1 && (               
            <Col lg={4}>
              <Form.Group  className="mb-3" controlId="filmFirst">
                <Form.Label>Es un òpera prima?</Form.Label>
                <Form.Control as="select"
                  required
                  name="filmFirst"
                  defaultValue={first}
                >
                  <option value=''>Selecciona una opció...</option>
                  <option value={true}>Si, es la primera que fa el director/a.</option>
                  <option value={false}>No, ja ha fet altres abans.</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
            { formStructure.indexOf('filmPremiere') !== -1 && (               
            <Col lg={8}>
            <Form.Group  className="mb-3" controlId="filmPremiere">
              <Form.Label>En cas de selecció, l'estrena serà la projecció al FIC-CAT?</Form.Label>
              <Form.Control as="select"
                required
                name="filmPremiere"
                defaultValue={premiere}
                onChange={ (event) => { event.target.value ==='Estrena' ? this.setState({ isPremiere: true }): this.setState({ isPremiere: false }) } }
              >
                <option value=''>Selecciona una opció...</option>
                <option value='Estrena'>Si, com a mínim serà ESTRENA a Catalunya.</option>
                <option value='Festivals'>No, L'obra ha estat estrenada prèviament a festivals.</option>
                <option value='Cinema'>No, L'obra ha estat estrenada prèviament en sales de cinema i/o festivals.</option>
                <option value='TV'>No, L'obra ha estat estrenada prèviament a la televisió i/o festivals i/o sales de cinema.</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
            </Form.Group>
            </Col>
          )}
        </Row>
        { (!isPremiere && formStructure.indexOf('filmFestivals') !== -1 ) && (
          <Form.Group  className="mb-3" controlId="filmFestivals">
            <Form.Label>Presència en festivals i premis.<br/> <small className='text-muted'>Indica aquí en quins festivals s'ha projectat i quins premis ha rebut el treball.</small></Form.Label>
            <Form.Control 
              as="textarea"
              rows="3"
              defaultValue={festivals}
              name="filmFestivals"
               />
          </Form.Group>
        )}
        </Card>
        <div className='p-1'></div>
        { formStructure.indexOf('filmLangCAT') !== -1 && (
        <>
        <h2 className='mt-4 mb-4 pb-2' >Llengua V.O. <small>Si us plau, indica la llengua o llengües de la producció en la seva versió original.</small></h2>
        <Card  bg="light" className='p-3 pb-2 mb-4'>
          <Alert variant='warning'><PiWarningDiamondFill /> <strong>Atenció:</strong> El FIC-CAT només selecciona produccions que siguin <strong>totalment o parcial en català.</strong></Alert>
          <Row>
          <Col sm={3}>
            <Form.Group  className="mb-3" controlId="filmLangCAT">
            <Form.Check>
              <Form.Check.Input
                required
                defaultChecked={langCAT}
                name="filmLangCAT"
              />
              <Form.Check.Label htmlFor="filmLangCAT"><strong>Català</strong> <small className='text-muted'>(Requerit)</small></Form.Check.Label>
              <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_CAT }</Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          </Col>
        { formStructure.indexOf('filmLangES') !== -1 && (
          <Col sm={3}>
              <Form.Group  className="mb-3" controlId="filmLangES">
              <Form.Check>
                <Form.Check.Input
                  name="filmLangES"
                  defaultChecked={langES}
                />
                <Form.Check.Label htmlFor="filmLangES">Castellà</Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Col>
        )}
        { formStructure.indexOf('filmLangEN') !== -1 && (
        <Col sm={3}>
          <Form.Group  className="mb-3" controlId="filmLangEN">
            <Form.Check>
              <Form.Check.Input
                name="filmLangEN"
                defaultChecked={langEN}
              />
              <Form.Check.Label htmlFor="filmLangEN">Anglès</Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
        )}
        { formStructure.indexOf('filmLangOT') !== -1 && (
        <Col sm={3}>
          <Form.Group  className="mb-3" controlId="filmLangOT">
            <Form.Check>
              <Form.Check.Input
                defaultChecked={langOT}
                name="filmLangOT"
              />
              <Form.Check.Label htmlFor="filmLangOT">Altres</Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Col>
        )}
        </Row></Card>
        </>
        )}
        { formStructure.indexOf('filmTarragona') !== -1 && (
        <div className='pt-2 pb-3'>
          <h2 className='mt-4 mb-4 pb-2'>Candidatura Comarques Tarragonines <small>Per poder optar al <strong>premi a la Millor Producció de les Comarques Tarragonines</strong> la producció ha de complir els següents criteris.</small></h2>
          <Card bg="light" className='p-3 pb-2 mb-4'>
            <p> Si us plau, marca només els criteris que es compleixin:</p>
            <Form.Group  className="mb-3" controlId="filmTarragonaStaff">
            <Form.Check>
              <Form.Check.Input
                defaultChecked={tarragonaStaff}
                name="filmTarragonaStaff"
              />
              <Form.Check.Label htmlFor="filmTarragonaStaff">  L’equip de <strong>direcció i/o producció</strong> és provinent de les Comarques Tarragonines.</Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Form.Group  className="mb-3" controlId="filmTarragonaLocation">
            <Form.Check>
              <Form.Check.Input
                defaultChecked={tarragonaLocation}
                name="filmTarragonaLocation"
              />
              <Form.Check.Label htmlFor="filmTarragonaLocation"> La producció <strong>ha estat rodada a les Comarques Tarragonines</strong> (totalment o parcial).</Form.Check.Label>
            </Form.Check>
          </Form.Group>
          </Card>
        </div>
        
        )}
      </div>
    )
  }

}



export default compose(
  withFirebase
)(FilmsFormInfo);
