import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import Card from 'react-bootstrap/Card';


import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';
import { Rating } from 'react-simple-star-rating'
import FilmsBadges from '../FilmsBadges';
import { FaExclamation, FaExclamationCircle, FaInfoCircle } from "react-icons/fa";
import { PiCheckCircleFill, PiWarningDiamondFill } from "react-icons/pi";
import { FaCakeCandles } from 'react-icons/fa6';

class FilmsListItem extends Component {
  constructor(props) {
    super(props);


    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
      variant: props.variant ? props.variant : 'default'
    }

  }

  componentDidMount () {
    if (this.state.variant !== 'small') {
      this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
        if (snapshotUserFilm.val()) {
          this.setState({
            userFilm: snapshotUserFilm.val()
          });
        } else {
          this.setState({
            userFilm: {
              userFilmNote: '',
              userFilmRank: ''
            }
          });
        }
      })
    }
  }

  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }
  

  render () {
    const { film, userFilm, filmType, variant } = this.state;
    const currentYear = new Date().getFullYear();
    const image = variant === 'wide' ? this.resizedImage(film.imageCover ? film.imageCover : film.imageFrame1) : this.resizedImage(film.imageFrame1 ? film.imageFrame1 : film.imageCover);
    const realLength = film.filmLengthFixed ? film.filmLengthFixed : film.filmLength;
    const imageCover = this.resizedImage(film.imageCover ? film.imageCover : film.imageFrame1);
    return (
      <>
        { (U.isUserAdmin() || (!U.isUserAdmin() && film.isSelectable)) && (
        <>
        { variant !== 'small' && (
          <>
            <Card className={variant} style={ userFilm && (userFilm.userFilmRank > 0) ? { background: 'rgba(40,167,69,.2)' } : {} }>
              <Card.Link href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid } style={{textDecoration: 'none'}}>
                <Card.Img variant="top" src={image ? image :'/img/no-image.png'} />
              <Card.Body className style={{ padding: '5px 10px 30px 10px', textDecoration: 'none'}}>
                <div style={{ padding: '0px 0px 5px 0px'}}>
                  <Rating size='20' initialValue={ userFilm && (userFilm.userFilmRank > 0) ? userFilm.userFilmRank : 0 } readonly={true} />
                </div>
                <div className='pb-3'>
                <span style={{ fontSize: '1.5em', lineHeight: '0', color: '#dc3545',textDecorationColor:'#dc3545'  }}><strong>{ film.filmName }</strong></span><br />          
                <small className='text-muted'> de <strong>{film.filmSchool ?  film.filmSchool : ( film.filmDirector ? film.filmDirector : film.contactName )}</strong> {film.contactBirth && filmType === 'jove' ? ( <><br /> {U.isJove(film.contactBirth) ? (<span><FaCakeCandles /> {U.datetoHuman(film.contactBirth, 'small')}</span>) : (<strong className='text-warning'><FaCakeCandles />  {U.datetoHuman(film.contactBirth, 'small')}</strong>) } </>) : ''} </small><br/>{film.filmSchoolLevel && (<small className='text-muted'>{film.filmSchoolLevel.toUpperCase()}<br/></small>)}
                <small className='text-muted p-'>
                  <span>{film.filmYear ? film.filmYear + ' · ' : ''}{realLength? realLength + ' min · ' : ''} {film.filmGenre ? film.filmGenre + ' · ' : ''}{film.filmCity}</span>, {film.filmState} ({film.filmCountry ? film.filmCountry.substring(0,2).toUpperCase() : ''})
                  {film.filmSignDate && (
                    <i>
                      <br />
                      Data d'inscripció: { isNaN(Date.parse(film.filmSignDate)) ? film.filmSignDate : U.datetoHuman(film.filmSignDate, 'small')}
                    </i>
                  )}

                </small>
                </div>
                <FilmsBadges key={film.uid} film={film} filmType={filmType}/>
              </Card.Body>
              </Card.Link>

            { U.isUserContentManager() && (
              <>
                <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid } className={'btn btn-sm text-white font-weight-bold ' + (film.isSelectable === undefined ? 'bg-warning' : (film.isSelectable === false ? 'bg-danger' : 'bg-success')) }>Administrar</a>
              </>
            )}
            </Card>
          </>
        )}
        { variant === 'small' && (
          <Card>
              <Card.Link href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid } style={{textDecoration: 'none'}}>
                <Card.Img variant="top" src={imageCover ? imageCover :'/img/no-image.png'} />
                <Card.Body>
                  <p className='mb-0 text-truncate'>{ film.filmName }</p>
                  <p className='mb-0'><small className='text-muted'>{film.filmLengthFixed ? film.filmLengthFixed : film.filmLength} min</small></p>
                </Card.Body>
                </Card.Link>
                { U.isUserContentManager() && (
                  <>
                    <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid } className={'btn btn-sm text-white ' + (film.isSelectable === undefined ? 'bg-warning' : (film.isSelectable === false ? 'bg-danger' : 'bg-success')) }>Administrar</a>
                  </>
                )}
              </Card>
        )}
        </>
        )}
      </>
    )
    
  }
}

export default compose(
  withFirebase
)(FilmsListItem);