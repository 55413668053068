export const FICCAT_DATE = "(Del 31 de maig al 7 de juny de 2025)";
export const FICCAT_EDITION = "17a";
export const FICCAT_PRESS = "Dijous 16 de maig de 2025 a les 11:00 hores";
export const FICCAT_GALA = "Dissabte 7 de juny a les 19:00 hores";
export const FICCAT_YOUNG = "dijous 20 de març des de les 15:00h fins al diumenge 23 de març a les 15:00h";


export const LLARGS = 'Llargmetratges';
export const LLARGS_FORM_DESC = "Ficció, animació i experimental de durada superior als 30 minuts.";
export const DOCS = 'Documentals';
export const DOCUMENTALS = 'Documentals';
export const DOCS_FORM_DESC = "Obres documentals de durada superior als 30 minuts.";
export const CURTS = 'Curtmetratges';
export const CURTS_FORM_DESC = "Obres de Ficció, animació, documental o experimental de durada inferior als 30 minuts.";
export const CENTRES = 'Centres Educatius';
export const CENTRESEDUCATIUS = 'Centres Educatius';
export const CENTRES_FORM_DESC = "Obres de Centres educatius (primària i secundària) de durada inferior als 10 minuts.";
export const VIDEOCLIPS = 'Videoclips';
export const VIDEOCLIPS_FORM_DESC = "Vídeos musicals de durada inferior als 10 minuts.";

export const JOVES = 'Concurs Jove';
export const JOVE = 'Concurs Jove';
export const JOVES_FORM_DESC = "Fins als 35 anys. Tindrà lloc el proper " + FICCAT_YOUNG + ". Curts de durada inferior als 3 minuts partint d'unes premisses i realitzats en 72 hores.";
export const JOVE_REQUIREMENTS_1 = "Amb motiu de l’eix temàtic del FIC-CAT 2025, 'Cinema i Educació', us demanem que en algun moment de l’obra, aparegui la frase 'L’educació salva vides”'. Pot ser dita o escrita, però intenteu que estigui contextualitzada, que el seu ús no es vegi forçat ;)"
export const JOVE_REQUIREMENTS_2 = "Heu de fer aparèixer el nom ÀLEX, ja sigui un personatge del curt, algú a qui es fa referència, o bé el nom escrit en un paper, feu volar la imaginació!"
export const JOVE_REQUIREMENTS_3 = ""
export const JOVE_DATE = "diumenge 1 de juny a les 12.30h"; // Data i hora de la projecció jove

export const PITCH = 'Pitch de projectes';
export const PITCH_FORM_DESC = "S’admetran projectes de llargmetratges i curtmetratges, tant de ficció com de documental."

export const SIGNIN_FILM = "Inscriure";
export const FIELD_ACCEPT = "Per continuar has d'acceptar aixó.";
export const FIELD_REQUIRED = 'Si us plau, omple correctament aquest camp';
export const FIELD_PASSWORD = 'Les contrassenyes no coincideixen';
export const FIELD_UNSELECTED = 'Si us plau, selecciona una opció';
export const FIELD_CAT = 'Només acceptem produccions parcial o totalment en català';
export const SIGIN_FALSE_TITLE = "Inscripcions tancades"
export const SIGIN_FALSE_DESC = "Gràcies pel teu interès, però ara mateix les inscripcions en aquesta categoria estan tancades. Segueix les nostres xarxes socials per saber quan obrim inscripcions per a la propera edició.";

export const IMAGECOVER = 'Cartell'
export const IMAGEFRAME1 = 'Fotograma principal'
export const IMAGEFRAME2 = 'Fotograma 2'
export const IMAGEFRAME3 = 'Fotograma 3'