export const ADMIN = 'ADMIN';
export const EDITOR = 'EDITOR';
export const FILMAKER = 'FILMAKER';
export const JURY = 'JURAT';
export const JURY_OFICIAL = 'OFICIAL';
export const JURY_YOUNG = 'JOVE';
export const JURY_SCHOOL = 'CENTRES';
export const PITCH = 'PITCH';
export const PROVIDER = 'PROVIDER';
export const ANONYMOUS = 'ANONYMOUS';

export const IsADMIN = [ADMIN];
export const IsEDITOR = [EDITOR];
export const IsFILMAKER = [EDITOR];
export const IsPROVIDER = [EDITOR];
export const IsJURY = [JURY,JURY_OFICIAL,JURY_YOUNG, JURY_SCHOOL];

