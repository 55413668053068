import React, { Component } from 'react';

import * as CONFIG from'./config';
import * as ROLES from'./roles';


export const forceUpdate = () => {
    window.location.reload(false);
}

export const datetoHuman = (date, type) => {
    let newDate = new Date(date);
    if (type === true || type === 'large') {
        return newDate.toLocaleDateString('ca-CA', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit"
          });
    } else if ( type === 'medium') {
        return newDate.toLocaleDateString('ca-CA', {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        });
    } else if ( type === 'small') {
        return newDate.toLocaleDateString('ca-CA', {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
        });
    } else {
        return newDate.toLocaleDateString('ca-CA', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }

}

export const isJove = (d1) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(new Date().setFullYear(new Date().getFullYear() - 35));
  
    if (date1 < date2) {
      return false
    } else if (date1 > date2) {
      return true
    } else {
        return true
    }
}

export const authUser = () => {
    if (localStorage.getItem('authUser')) {
        return JSON.parse(localStorage.getItem('authUser'));
    } else {
        return { roles:{ [ROLES.ANONYMOUS]: true } };
    }
}

export const authUserJuryType = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.JURY]) {
        return authUser().roles[ROLES.JURY]
        
    } else {
        return false;
    }
}

export const isUserAnonymous = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.ANONYMOUS]) {
        return true;
    } else {
        return false;
    }
}

export const isUserJury = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.JURY]) {
        return true;
    } else {
        return false;
    }
}

export const isUserJuryOficial = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.JURY] === ROLES.JURY_OFICIAL ) {
        return true;
    } else {
        return false;
    }
}


export const isUserJuryYoung = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.JURY] === ROLES.JURY_YOUNG ) {
        return true;
    } else {
        return false;
    }
}

export const isUserJurySchool = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.JURY] === ROLES.JURY_SCHOOL ) {
        return true;
    } else {
        return false;
    }
}

export const isUserPitch = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.PITCH]) {
        return true;
    } else {
        return false;
    }
}


export const isUserEditor = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.EDITOR]) {
        return true;
    } else {
        return false;
    }
}



export const isUserFilmaker = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.FILMAKER]) {
        return true;
    } else {
        return false;
    }
}

export const isUserDistributor = () => {
    if (authUser() && authUser().isDistributor === true) {
        return true;
    } else {
        return false;
    }
}

export const getUSerFilms = () => {
    if (isUserFilmaker()) {
        return authUser().roles[ROLES.FILMAKER];
    } else {
        return [];
    }
}

export const isUserAdmin = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.ADMIN]) {
        return true;
    } else {
        return false;
    }
}

export const isUserProvider = () => {
    if (authUser() && authUser().roles && authUser().roles[ROLES.PROVIDER]) {
        return true;
    } else {
        return false;
    }
}


export const isUserContentManager = () => {
    if (isUserEditor() || isUserAdmin() ) {
        return true;
    } else {
        return false;
    }
}

export const isUserCommittee = () => {
    if (isUserEditor() || isUserAdmin() || isUserRegular() || isUserPitch() ) {
        return true;
    } else {
        return false;
    }
}

export const showTopFilmsPersonal = () => {
    if (isUserAdmin() || ((isUserRegular() || isUserEditor() ) )  ) {
        return true;
    } else {
        return false;
    }
}


export const showTopFilms = () => {
    if (isUserAdmin() || ((isUserRegular() || isUserEditor() ) && ((!activeEditionIsCurrent() && CONFIG.TOP_FILMS === false) || CONFIG.TOP_FILMS === true) )  ) {
        return true;
    } else {
        return false;
    }
}




export const isUserRegular = () => {
    if ( authUser() && authUser().roles && authUser().email !== undefined && !authUser().roles[ROLES.ANONYMOUS] && !authUser().roles[ROLES.PITCH] && !authUser().roles[ROLES.JURY] && !authUser().roles[ROLES.PROVIDER] && !authUser().roles[ROLES.EDITOR] && !authUser().roles[ROLES.ADMIN] && !authUser().roles[ROLES.FILMAKER]) {
        return true;
    } else {
        return false;
    }
}

export const setActiveEdition = (e, force) => {
    
    let year = '';

    if (e.target && e.target.value) {
        year = Math.round(e.target.value);
    } else {
        year = Math.round(e);
    }
 
    localStorage.setItem("currentEdition", year);

    if (force) {
        forceUpdate();
    }
}


export const activeEdition = () => {
    if (localStorage.getItem('currentEdition') === null || localStorage.getItem('currentEdition') === '' ) {
        return CONFIG.CURRENT_EDITION;
      } else {
        return localStorage.getItem('currentEdition');
      } 
}

export const activeEditionIsCurrent = () => {
    if (activeEdition() === CONFIG.CURRENT_EDITION ) {
        return true;
      } else {
        return false;
      } 
}

export const scrollToFirstError = (e) => {
    const items = document.querySelectorAll(".form-control:invalid");
    const visible = [...items].filter((el) => {
      // jQuery-like :visible selector
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if (visible.length > 0) {
      items[0].focus()
    }

    e.stopPropagation();
}


