import React, { Component } from 'react';
import { withRouter,Redirect, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as U from '../../constants/utilities';
import Loading from '../Loading';
import { Container, Row, Col, Card } from 'react-bootstrap';

const SignInPage = () => (
  <Container className="pt-4">
      <Row className="justify-content-md-center">
        <Col lg={6}>
          <h2 className="my-4">Accés d'usuaris registrats</h2>
          <Card bg="light" className='mb-4'>
            <Card.Body>
              <SignInForm />
              {/*
              <SignInGoogle />
              <SignInFacebook />
              <SignInTwitter />
              */}
          </Card.Body>
          </Card>
          <PasswordForgetLink />
          <SignUpLink />
          </Col>
      </Row>
    </Container>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  logged: false,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then (authUser => {
        this.setState({ ...INITIAL_STATE });
        this.setState({ logged: true })
          window.location = window.location.origin + ROUTES.DASHBOARD
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error, logged } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <>
        {(U.isUserAnonymous() && !logged) ? (
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              className="form-control"
              type="text"
              placeholder="Adreça d'email"
            />
          </div>
          <div className="form-group">
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            className="form-control"
            type="password"
            placeholder="Password"
          />
          </div>
          <button disabled={isInvalid} type="submit" className="btn btn-success btn-lg">
            Entrar
          </button>
          {error && <p>{error.message}</p>}
        </form>
        ): (
          <Loading />
        )}
        { (!U.isUserAnonymous() || logged) && (
        <>
        { U.isUserProvider() && (
          <Redirect to={ROUTES.CHOICE_STUFF_CURTS} />
        )}
        { U.isUserJuryYoung() && (
          <Redirect to={ROUTES.CHOICE_JOVES} />
        )}
          { U.isUserJurySchool() && (
          <Redirect to={ROUTES.CHOICE_CENTRES} />
        )}
        { (U.isUserJuryOficial() || U.isUserCommittee()) && (
          <Redirect to={ROUTES.CHOICE_LLARGS} />
        )}
        </>
        )}
      </>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
