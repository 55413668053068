import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";
import { FaEnvelope, FaFileCsv, FaFileExport, FaFilm } from 'react-icons/fa';




class FilmsListFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filmType: props.filmType,
      urlBase: props.urlBase,
      filter: props.filter,
      userRanking: props.userRanking,
      view: props.view ? props.view : 'normal',
      filters: props.filters ? props.filters : [],
      firebase: props.firebase,
    }

  }

  sanitizeURL(url, forceParameter) {
    let sanitizedURL = url;
    if (this.state.userRanking) {
      if (forceParameter !== 'comite' && forceParameter !== 'personal') {
        sanitizedURL = sanitizedURL.replace('comite', this.state.userRanking).replace('personal', this.state.userRanking);
      } else {
        sanitizedURL = sanitizedURL.replace(this.state.userRanking, forceParameter).replace('comite', forceParameter).replace('comite-educatius' ,'centres-educatius');

      }
    }

    return sanitizedURL
  }

  render () {
    const { film, userFilm, filter, filmType, userRanking } = this.state
    return (
      <>
      { U.isUserCommittee() && this.state.filters.indexOf('view') !== -1 && (
        <p>
          <small className='text-muted'>Vista:</small>&nbsp;&nbsp;
          <Link className={ window.location.pathname.indexOf('normal') !== -1 ? 'selected' : '' } to={ window.location.pathname.replace('email','normal')} onClick={() => {window.location.href = window.location.pathname.replace('email','normal').replace('premsa','normal').replace('material','normal').replace('export','normal') }}><FaFilm /><span className="d-none d-sm-inline"> Fitxa</span></Link>&nbsp;|&nbsp;
          <Link className={ window.location.pathname.indexOf('email') !== -1 ? 'selected' : '' }to={ window.location.pathname.replace('normal','email')} onClick={() => {window.location.href = window.location.pathname.replace('normal','email').replace('premsa','email').replace('material','email').replace('export','email')  }} ><FaEnvelope /><span className="d-none d-sm-inline"> Contactes</span></Link>
          { U.isUserAdmin() && this.state.filters.indexOf('export') !== -1 && (
            <>
          &nbsp;|&nbsp;
          <Link className={ window.location.pathname.indexOf('export') !== -1 ? 'selected' : '' }to={ window.location.pathname.replace('normal','export').replace('email','export')} onClick={() => {window.location.href = window.location.pathname.replace('normal','export').replace('email','export').replace('material','export') }} ><FaFileCsv /><span className="d-none d-sm-inline"> Exportar</span></Link>
          </>
          )}
          { window.location.pathname.indexOf('seleccio') !== -1 &&  (
          <>
            { U.isUserContentManager() && (
            <>
              &nbsp;|&nbsp;
              <Link className={ window.location.pathname.indexOf('premsa') !== -1 ? 'selected' : '' }to={ window.location.pathname.replace('normal','premsa')} onClick={() => {window.location.href = window.location.pathname.replace('normal','premsa').replace('email','premsa').replace('material','premsa') }} >Premsa</Link>
            </>
            )}
            { (U.isUserContentManager() || U.isUserProvider()) && (
            <>  
              &nbsp;|&nbsp;
              <Link className={ window.location.pathname.indexOf('material') !== -1 ? 'selected' : '' }to={ window.location.pathname.replace('normal','material')} onClick={() => {window.location.href = window.location.pathname.replace('normal','material').replace('email','material').replace('premsa','material') }} >Materials</Link>
            </>
            )}
          </>
          )}
        </p>
      )}
      { this.state.filters.indexOf('user') !== -1 && (
        <p>
          <small className='text-muted'>Usuaris: </small>
          {this.state.filmType !== 'JOVES' && (
            <>
            <Link className={ filter === 'personal' ? 'selected' :'' } to={ this.sanitizeURL(ROUTES['TOP_' + filmType ], 'personal') } onClick={() => {window.location.href= this.sanitizeURL(ROUTES['TOP_' + filmType ], 'personal') }}>Personal</Link>
            { U.showTopFilms() && (
            <>
            &nbsp;|&nbsp;
            <Link className={ filter === 'comite' ? 'selected' :'' } to={ this.sanitizeURL(ROUTES['TOP_' + filmType ], 'comite') } onClick={() => {window.location.href= this.sanitizeURL(ROUTES['TOP_' + filmType ], 'comite') }}>Comité</Link>
            </>
            )}
            </>
          )}
          {U.isUserAdmin() && (
            <>
            &nbsp;|&nbsp;
            <Link className={ (filter !== 'comite' && filter !== 'personal') ? 'selected' :'' } to={ this.sanitizeURL(ROUTES['TOP_' + this.state.filmType ]) }  onClick={() => {window.location.href= this.sanitizeURL(ROUTES['TOP_' + this.state.filmType ]) }}>Jurat</Link>
            </>
         )}
        </p>
      )}
      { this.state.filters.indexOf('pending') !== -1 && (
        <p>
          <small className='text-muted'>Mostrar: </small>
          <Link className={ window.location.pathname.indexOf('pendents') === -1 ? 'selected' : '' } to={ window.location.pathname.replace('pendents','tots')} onClick={() => {window.location.href = window.location.pathname.replace('pendents','tots') }}>Tots</Link>&nbsp;|&nbsp;
          <Link className={ window.location.pathname.indexOf('pendents') !== -1 ? 'selected' : '' } to={ window.location.pathname.replace('tots','pendents')} onClick={() => {window.location.href = window.location.pathname.replace('tots','pendents').replace('primaria','pendents').replace('secundaria','pendents') }}><span className="d-none d-sm-inline"> Només </span>Pendents</Link>
        </p>
      )}
      { this.state.filmType === 'CENTRES' && this.state.filters.indexOf('school') !== -1 && (
        <p>
          <small className='text-muted'>Curs: </small>
          <Link className={ window.location.pathname.indexOf('primaria') === -1 && window.location.pathname.indexOf('secundaria') === -1 ? 'selected' : '' } to={ this.state.urlBase.replace('normal', this.state.view) }>Tots</Link>
          &nbsp;|&nbsp;
          <Link className={ window.location.pathname.indexOf('primaria') !== -1 ? 'selected' : '' } to={ this.state.urlBase.replace('tots','primaria').replace('normal', this.state.view) }>Primaria</Link>
          &nbsp;|&nbsp;
          <Link className={ window.location.pathname.indexOf('secundaria') !== -1 ? 'selected' : '' } to={ this.state.urlBase.replace('tots','secundaria').replace('normal', this.state.view) }>Secundaria</Link>
        </p>
      )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsListFilters);